import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InternalUserModel } from "../../../models/UserModel";
import Client from "../../../api-services/admin-panel/client";
import Select from "react-select";
import { MainContainer } from "../../../common/layout/Layout.style";
import { style } from "../../../common/select-style/SelectStyle";

const EditInternalUser = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [user, setUser] = useState(new InternalUserModel());
  const [roles, setRoles] = useState([]);

  var client = new Client();

  useEffect(() => {
    if (id) {
      getUser(id);
    }
    getAllRoles();
  }, []);

  const getUser = async (id) => {
    let data = await client.GetInternalUser(id);
    setUser({
      ...data,
      roles: data.baseRoles.map((x) => {
        return { value: x.code, label: x.description };
      }),
      inheritedRoles: data.inheritedRoles.map((x) => {
        return { value: x.code, label: x.description };
      }),
      defaultRoles: data.defaultRoles.map((x) => {
        return { value: x.code, label: x.description };
      }),
    });
  };

  const getAllRoles = async () => {
    let response = await client.GetAllRoles();
    let data = response.map((x) => {
      return { value: x.code, label: x.description };
    });
    setRoles(data);
  };

  const editUserRoles = async () => {
    let data = {
      employeeId: user.employeeId,
      roleCodes: user.roles.map((x) => {
        return x.value;
      }),
    };
    let response = await client.AssignRoleToInternalUser(data);
  };

  return (
    <>
      <MainContainer>
        <h4>Izmena internog korisnika</h4>
        <div className="form-group col-md-4">
          <label>{t("Active")}: {user.enabled ? t("Yes") : t("No")}</label>
        </div>
        <div className="form-group col-md-4">
          <label>{t("UserName")}</label>
          <input
            type="text"
            className="form-control"
            value={user.userName}
            onChange={(e) => setUser({ ...user, userName: e.target.value })}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Name")}</label>
          <input
            type="text"
            className="form-control"
            value={user.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("LastName")}</label>
          <input
            type="text"
            className="form-control"
            value={user.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>Podrazumevane role</label>
          <Select
            placeholder={""}
            options={user.defaultRoles}
            menuPortalTarget={document.body}
            styles={style}
            value={user.defaultRoles}
            isMulti
            isDisabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>Nasleđene role</label>
          <Select
            placeholder={""}
            options={user.inheritedRoles}
            menuPortalTarget={document.body}
            styles={style}
            value={user.inheritedRoles}
            isMulti
            isDisabled
          />
        </div>
        <div className="form-group col-md-4">
          <label>Role</label>
          <Select
            placeholder={t("SelectFromList")}
            options={roles}
            onChange={(e) => {
              setUser({ ...user, roles: e });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={user.roles}
            isMulti
          />
        </div>
        <button
          type="button"
          className="btn btn-accept"
          onClick={editUserRoles}
        >
          Izmeni role
        </button>
      </MainContainer>
    </>
  );
};

export default EditInternalUser;
