import React from "react";

const TypeEnum = {
  1: "Prilog 1",
  2: "Prilog 2",
};

const AttachmentType = ({ value }) => {
  return <span title={TypeEnum[value]}>{TypeEnum[value]}</span>;
};

export default AttachmentType;
