import React, { useEffect } from "react";
import useAppUrl from "../../../hooks/useAppUrl";

const NotAllowed = () => {
    const { setAppPath, setAppName } = useAppUrl();
  useEffect(() => {
    setAppName("");
    setAppPath("/");
  }, [])
  
  return (
    <>
      <h2>
        Nemate dovoljan nivo privilegija da biste pristupili ovoj stranici!
      </h2>
    </>
  );
};

export default NotAllowed;
