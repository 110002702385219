class PersonModel {
  constructor(
    id = 0,
    firstName = "",
    lastName = "",
    companyName = "",
    foreigner = false,
    identityCardNumber = "",
    identityCardPlace = "",
    identityPassportNumber = "",
    identityPassportPlace = "",
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.companyName = companyName;
    this.foreigner = foreigner;
    this.identityCardNumber = identityCardNumber;
    this.identityCardPlace = identityCardPlace;
    this.identityPassportNumber = identityPassportNumber;
    this.identityPassportPlace = identityPassportPlace;
  }
}

export { PersonModel };
