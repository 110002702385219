class CompanyModel {
    constructor(
        companyName = "",
        address = "",
        email = "",
        pib = "",
        fromSap = false,
        daughterCompany = false
    ) {
        this.companyName = companyName;
        this.address = address;
        this.email = email;
        this.pib = pib;
        this.fromSap = fromSap;
        this.daughterCompany = daughterCompany;
    }
}

export { CompanyModel };