import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import DialogModal from "../../../common/modal/Modal.component";
import Client from "../../../api-services/admin-panel/client";
import SearchUser from "../../../components/admin-panel/search-user/searchUser.component";
import { SearchUserModel } from "../../../models/UserModel";
import { MainContainer } from "../../../common/layout/Layout.style";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";

const ExternalUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "External_Users";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [filterData, setFilterData] = useState(new SearchUserModel());
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState({});

  const schema = {
    newPassword: Joi.string().regex(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/).optional()
  }

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("Email"),
      accessor: "email",
    },
    {
      Header: t("CompanyName"),
      accessor: "company.companyName",
    },
    {
      Header: t("Active"),
      accessor: "enabled",
      Cell: ({ value }) => {
        return value ? t("Yes") : t("No");
      },
    },
    {
      accessor: "buttons",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangeUser")}
              onClick={() => getUser(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("PasswordChange")}
              onClick={() => {
                setUser({
                  id: row.original.id,
                  name: row.original.firstName + " " + row.original.lastName,
                });
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("key")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const getUser = async (id) => {
    navigate("/AdminPanel/CreateUser/" + id);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("EXTERNAL_USER_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    let filter = storedObject ? parsedObject : filterData;

    var response = await client.SearchExternalUsers({
      filter: {
        ...filter,
        companyIdentificators: filter.companyIdentificators
          ? [filter.companyIdentificators.companyIdentifier]
          : [],
      },
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    if (response && response != "Error")
      return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("EXTERNAL_USER_FILTER", JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("EXTERNAL_USER_FILTER");
    let model = new SearchUserModel();
    setFilterData(model);
    var response = await client.SearchExternalUsers({
      filter: model,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const handlePasswordChange = (value) => {
    const obj = { name: "newPassword", value: value };
    const result = handleSave(obj, null, errors, schema, t);
    setNewPassword(value);
    setErrors(result.errors);
  };

  const checkValidation = () => {
    const errorsResult = validateForm({ newPassword }, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      ShowAlertDialog();
    }
  }

  const resetPassword = async () => {
    let data = { id: user.id, password: newPassword };
    let response = await client.ResetPassword(data);
    if (response != "Error") {
      setNewPassword("");
      HideDialog();
    }
  };

  return (
    <MainContainer>
      <SearchUser
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
        internalUsers={false}
      />
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
        onClick={() => navigate("/AdminPanel/CreateUser")}
      >
        Kreiranje korisnika
      </button>
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={"id"}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={"Izmena lozinke korisnika " + user?.name}
      >
        <div className="form-group">
          <label>Nova lozinka</label>
          <input
            type="password"
            className="form-control"
            value={newPassword}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
          {errors?.newPassword && (
            <div className="text-danger">{errors.newPassword}</div>
          )}
        </div>
        <button
          type="button"
          className="btn btn-incomplete pull-right"
          onClick={checkValidation}
        >
          {t("ChangePassword")}
        </button>
      </DialogModal>
      {showAlert && (
        <AlertDialog
          handleYes={() => {
            HideAlertDialog();
            resetPassword();
          }}
          handleNo={() => {
            HideAlertDialog();
          }}
          show={showAlert}
          contentText={"Lozinka će biti izmenjena."}
          handleYesText={t("Accept")}
          className={"btn btn-accept"}
        ></AlertDialog>
      )}
    </MainContainer>
  );
};

export default ExternalUsers;
