import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ObjectModel } from "../../../models/ObjectModel";
import Client from "../../../api-services/admin-panel/client";
import AsyncSelect from "react-select/async";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainer } from "../../../common/layout/Layout.style";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import { style } from "../../../common/select-style/SelectStyle";

const CreateCodebook = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [object, setObject] = useState(new ObjectModel());
  const [licenceTypes, setLicenceTypes] = useState([]);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [fkParentError, setFkParentError] = useState(false);
  const [authError, setAuthError] = useState(false);

  const schema = {
    level: Joi.number().required(),
    name: Joi.string().required(),
  };

  const levels = [
    {
      label: "Prvi",
      value: 1,
    },
    {
      label: "Drugi",
      value: 2,
    },
    {
      label: "Treći",
      value: 3,
    },
  ];

  var client = new Client();

  useEffect(() => {
    if (id) {
      getObject(id);
    }
    getFirstLevelObjects();
    getSecondLevelObjects();
    getLicenceTypes();
  }, []);

  const getObject = async (id) => {
    let data = await client.GetObject(id);
    setObject({
      ...data,
      fkParentObject: {
        name: data.fkParentObjectNavigation?.name,
        id: data.fkParentObject,
      },
      licenceTypeIds: data.objectLicenceTypes.map((x) => {
        return {
          value: x.fkLicenceTypeNavigation.id,
          label: x.fkLicenceTypeNavigation.name,
        };
      }),
      objectAuthorizedPeopleEmployeeIds: data.objectAuthorizedPeople.map(
        (x) => {
          return x.authorizedPersonEmployee;
        }
      ),
    });
    setEdit(true);
  };

  const getFirstLevelObjects = async (value) => {
    let data = {
      filter: {
        level: 1,
        name: value,
      },
    };
    let response = await client.SearchObjects(data, true);
    return response.results;
  };

  const getSecondLevelObjects = async (value) => {
    let data = {
      filter: {
        level: 2,
        name: value,
      },
    };
    let response = await client.SearchObjects(data, true);
    return response.results;
  };

  const handleObjectChange = (value) => {
    setObject({ ...object, fkParentObject: value });
    if (value) {
      setFkParentError(false);
    }
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, object, errors, schema, t);
    setObject(result.data);
    setErrors(result.errors);
  };

  const getLicenceTypes = async () => {
    let response = await client.SearchLicenceTypes({});
    let res = response.results.map((x) => {
      return { value: x.id, label: x.name };
    });
    setLicenceTypes(res);
  };

  const createObject = async () => {
    const errorsResult = validateForm(object, schema, t);
    setErrors(errorsResult);

    let parentError = object.level > 1 && !object.fkParentObject?.id;
    setFkParentError(parentError);

    let error = object.level != 1 && object.objectAuthorizedPeopleEmployeeIds.length < 1;
    setAuthError(error);

    if (!errorsResult && !parentError && !error) {
      let data = {
        ...object,
        fkParentObject: object.fkParentObject?.id,
        licenceTypeIds: object.licenceTypeIds.map((x) => {
          return x.value;
        }),
        objectAuthorizedPeopleEmployeeIds:
          object.objectAuthorizedPeopleEmployeeIds.map((x) => {
            return x.employeeId;
          }),
      };
      if (edit) {
        var response = await client.UpdateObject(object.id, data);
      } else {
        var response = await client.CreateObject(data);
      }
      if (response != "Error") {
        navigate("/AdminPanel");
      }
    }
  };

  const handleChange = (value) => {
    setObject({ ...object, objectAuthorizedPeopleEmployeeIds: value });
    if (value.length > 0) {
      setAuthError(false);
    }
  };

  const getEmployees = async (value) => {
    if (value.length > 2) {
      let data = { filter: { usernameOrEmail: value }, page: 1, pageSize: 10 };
      if (object.level == 2) {
        data.filter.roleCodes = [4];
      } else if (object.level == 3) {
        data.filter.roleCodes = [5];
      }
      let response = await client.SearchInternalUsers(data, true);
      return response.results;
    }
  };

  return (
    <MainContainer>
      <h4>{edit ? t("ObjectChange") : t("ObjectCreate")}</h4>
      <div className="form-group col-md-4">
        <label>{t("ObjectLevel")}</label>
        <Select
          placeholder={t("SelectFromList")}
          options={levels}
          onChange={(e) => {
            handlePropertyChange("level", e.value);
          }}
          menuPortalTarget={document.body}
          styles={style}
          value={levels.filter((x) => x.value == object.level)}
          isDisabled={edit}
        />
        {errors?.level && (
          <div className="text-danger">{errors.level}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("ObjectName")}</label>
        <input
          type="text"
          className="form-control"
          value={object.name}
          onChange={(e) => handlePropertyChange("name", e.target.value)}
        />
        {errors?.name && (
          <div className="text-danger">{errors.name}</div>
        )}
      </div>
      {object?.level != 1 && (
        <>
          {object.level == 3 && (
            <div className="form-group col-md-4">
              <label>{t("SecondLevelObject")}</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                styles={style}
                value={object.fkParentObject}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={getSecondLevelObjects}
                onChange={handleObjectChange}
                placeholder="Potrebno je uneti minimum 3 karaktera..."
                isClearable
              />
              {fkParentError && (
                <div className="text-danger">{t("ObjectSecondLevelError")}</div>
              )}
            </div>
          )}
          {object.level == 2 && (
            <div className="form-group col-md-4">
              <label>{t("FirstLevelObject")}</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                styles={style}
                value={object.fkParentObject}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={getFirstLevelObjects}
                onChange={handleObjectChange}
                placeholder="Potrebno je uneti minimum 3 karaktera..."
                isClearable
              />
              {fkParentError && (
                <div className="text-danger">{t("ObjectFirstLevelError")}</div>
              )}
            </div>
          )}
          {/* <div className="form-group">
            <label>Tipovi dozvola</label>
            <Select
              placeholder={t("SelectFromList")}
              options={licenceTypes}
              onChange={(e) => {
                setObject({ ...object, licenceTypeIds: e });
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={object.licenceTypeIds}
              isMulti
            />
          </div> */}
          <div className="form-group col-md-4">
            <label>{t("AuthorizedPersons")}</label>
            <AsyncSelect
              cache={false}
              defaultOptions
              styles={style}
              value={object.objectAuthorizedPeopleEmployeeIds}
              getOptionLabel={(e) => e.userName + " (" + e.email + ")"}
              getOptionValue={(e) => e.id}
              loadOptions={getEmployees}
              onChange={handleChange}
              placeholder="Potrebno je uneti minimum 3 karaktera..."
              isClearable
              isMulti
            />
            {authError && (
              <div className="text-danger">
                {t("AtLeastOneAuthPersonError")}
              </div>
            )}
          </div>
        </>
      )}
      <button type="button" className="btn btn-accept" onClick={createObject}>
        {edit ? t("ChangeObject") : t("CreateObject")}
      </button>
    </MainContainer>
  );
};

export default CreateCodebook;
