import { useTranslation } from "react-i18next";
import Select from "react-select";
import { style } from "../../../common/select-style/SelectStyle";

const SearchCompany = ({
  filter,
  setFilter,
  searchData,
  resetFilter,
  allFilters,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t("Yes"),
    },
    {
      value: false,
      label: t("No"),
    },
  ];

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{allFilters ? t("company") : t("CompanyName")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.companyName}
            onChange={(e) =>
              setFilter({ ...filter, companyName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>
            {allFilters ? t("companyAddress") : t("CompanyAddress")}
          </label>
          <input
            type="text"
            className="form-control"
            value={filter.address}
            onChange={(e) => setFilter({ ...filter, address: e.target.value })}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{allFilters ? t("companyEmail") : t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.email}
            onChange={(e) => setFilter({ ...filter, email: e.target.value })}
          />
        </div>
        {allFilters && (
          <>
            <div className="form-group col-md-4">
              <label>{t("PIB")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.pib}
                onChange={(e) => setFilter({ ...filter, pib: e.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <label>{t("companyFromSAP")} &nbsp;</label>
              <Select
                placeholder={t("SelectFromList")}
                options={options}
                onChange={(e) => {
                  setFilter({ ...filter, fromSap: e?.value });
                }}
                menuPortalTarget={document.body}
                styles={style}
                value={options.filter((x) => x.value === filter.fromSap)}
                isClearable
              />
            </div>
          </>
        )}
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              searchData();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              resetFilter();
            }}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchCompany;
