import { useTranslation } from "react-i18next";
import Select from "react-select";
import { style } from "../../../common/select-style/SelectStyle";

const SearchObject = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
}) => {
  const { t } = useTranslation();
  const levelOptions = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
  ];

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("ObjectName")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.name}
            onChange={(e) => {
              setFilterData({ ...filterData, name: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ObjectLevel")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={levelOptions}
            onChange={(e) => {
              setFilterData({ ...filterData, level: e?.value });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={levelOptions.filter((x) => x.value === filterData.level)}
            isClearable
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleCallback();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={resetFilters}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchObject;
