import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { MainContainer } from "./Layout.style";
import Navbar from "../navigation/Navigation.component";
import LoadingSpinner from "../loader/Loader.component";
import API from "../../api";
const Layout = () => {
  const [loading, setLoading] = useState(false);

  //isBackground- da li se funkcija izvrsava u pozadini ili ne,
  //potrebno je da se ne prikazuje loader na svaki get

  useEffect(() => {
    API.interceptors.request.use(
      (config) => {
        if (!config.isBackground) {
          setLoading(true);
        }
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    API.interceptors.response.use(
      function (response) {
        setLoading(false);

        return response;
      },
      async function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      {loading ? <LoadingSpinner /> : <></>}
      <Navbar />
      <Outlet />
    </>
  );
};

export default Layout;
