import React from 'react';

const StatusEnum = {
  1: "Novi", // New
  2: "Čeka potpis", // WaitingForSignature
  3: "Čeka odobrenje", // PendingApproval 
  4: "Odobren", // Approved 
  5: "Odbijen", // Rejected
  6: "Nepotpun", // Incomplete
  7: "Novi dopunjen", // NewAdded
  8: "Neispravno potpisan", // IncorrectlySigned
  9: "Ponovo potpisan", // SignedAgain
};

export const StatusForSecondStep = [0, 1, 7];
export const StatusForFourthStep = [3, 9];
export const StatusWithComment = [5, 6, 8];

const InputStatus = ({ value }) => {
  return <span title={StatusEnum[value]}>{StatusEnum[value]}</span>;
};

export default InputStatus;