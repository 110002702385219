class ObjectModel {
    constructor(
        name = "",
        level = undefined,
        fkParentObject = 0,
        licenceTypeIds = [],
        objectAuthorizedPeopleEmployeeIds = []
    ) {
        this.name = name;
        this.level = level;
        this.fkParentObject = fkParentObject;
        this.licenceTypeIds = licenceTypeIds;
        this.objectAuthorizedPeopleEmployeeIds = objectAuthorizedPeopleEmployeeIds;
    }
}

export { ObjectModel };