import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import SearchObject from "../../../components/admin-panel/search-object/searchObject.component";
import Client from "../../../api-services/admin-panel/client";
import { MainContainer } from "../../../common/layout/Layout.style";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const Codebooks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "Codebooks";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    name: "",
    level: undefined,
  });

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("ObjectName"),
      accessor: "name",
    },
    {
      Header: t("ParentObjectName"),
      accessor: "fkParentObjectNavigation.name",
    },
    {
      Header: t("ObjectLevel"),
      accessor: "level",
    },
    // {
    //   Header: "Tipovi dozvola",
    //   accessor: "objectLicenceTypes",
    // },
    // {
    //   Header: "Ovlašćena lica",
    //   accessor: "objectAuthorizedPeople",
    // },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ObjectChange")}
              onClick={() => editObject(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
            <ButtonWithConfirmation
              className="btn btn-danger table-btn"
              title={t("DeleteObject")}
              onClick={() => {
                deleteObject(row.original.id);
              }}
              contentText={'Objekat "' + row.original.name + '" će biti trajno obrisan.'}
              handleYesText={t("Accept")}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </ButtonWithConfirmation>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("CODEBOOKS_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.SearchObjects({
      filter: storedObject ? parsedObject : filterData,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("CODEBOOKS_FILTER", JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("CODEBOOKS_FILTER");
    let data = {
      name: "",
      level: undefined,
    };
    setFilterData(data);
    var response = await client.SearchObjects({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const editObject = async (id) => {
    navigate("/AdminPanel/CreateCodebook/" + id);
  };

  const deleteObject = async (id) => {
    let response = await client.DeleteObject(id);
    if (response != "Error") {
      searchData();
    }
  }

  return (
    <MainContainer>
      <SearchObject
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
      />
      <div style={{ marginBottom: "10px" }}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate("/AdminPanel/CreateCodebook")}
        >
          Dodaj objekat
        </button>
      </div>
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={"id"}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default Codebooks;
