import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DeleteButton,
  NewFileBtn,
} from "../../../common/multiple-files/multipleFiles.style";
import DialogModal from "../../../common/modal/Modal.component";
import api from "../../../api";
import { env } from "../../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;

const InputDocuments = ({
  firstAttachment,
  setFirstAttachment,
  firstAttachmentRef,
  secondAttachment,
  setSecondAttachment,
  secondAttachmentRef,
  bzrWithContract,
  bzrCreated,
  inputId,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const downloadFirstAttachment = async () => {
    let url =
      baseURL +
      "/InputWorkflow/DownloadAttachmentOneFourthStepBzr?id=" +
      inputId;
    let fileName = "Prilog 1";
    getDocument(url, fileName);
  };

  const downloadSecondAttachment = async () => {
    let url =
      baseURL +
      "/InputWorkflow/DownloadAttachmentTwoFourthStepBzr?id=" +
      inputId;
    let fileName = "Prilog 2";
    getDocument(url, fileName);
  };

  const handleUploadFirstAttachmentClick = () => {
    firstAttachmentRef.current.value = null;
    firstAttachmentRef.current?.click();
  };

  const removeFirstAttachment = () => {
    setFirstAttachment(null);
    firstAttachmentRef.current.value = null;
  };

  const handleUploadSecondAttachmentClick = () => {
    secondAttachmentRef.current.value = null;
    secondAttachmentRef.current?.click();
  };

  const removeSecondAttachment = () => {
    setSecondAttachment(null);
    secondAttachmentRef.current.value = null;
  };

  const getDocument = async (urlName, fileName) => {
    let response = await api.get(urlName, {
      responseType: "blob",
    });
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(fileName);
    ShowDialog();
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = docName;
    link.click();
  };

  return (
    <>
      <div className="form-group col-6">
        <label>{t("FirstAttachment")}</label>
        <br />
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={downloadFirstAttachment}
        >
          {t("DownloadFirstAttachment")}
        </button>
        {firstAttachment ? (
          <>
            {firstAttachment?.name}
            <DeleteButton
              title={t("DeleteFile")}
              type="button"
              onClick={() => removeFirstAttachment()}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </DeleteButton>
          </>
        ) : (
          <NewFileBtn onClick={handleUploadFirstAttachmentClick} type="button">
            <FontAwesomeIcon icon={solid("plus")} />
            {t("UploadFirstAttachment")}
          </NewFileBtn>
        )}
        <input
          type="file"
          ref={firstAttachmentRef}
          onChange={(event) => setFirstAttachment(event.target.files[0])}
          hidden
          accept=".pdf"
          name="firstAttachment"
        />
      </div>
      {bzrWithContract && (
        <div className="form-group col-6">
          <label>{t("SecondAttachment")}</label>
          <br />
          <button
            type="button"
            className="btn btn-primary m-2"
            onClick={downloadSecondAttachment}
          >
            {t("DownloadSecondAttachment")}
          </button>
          {bzrCreated &&
            (secondAttachment ? (
              <>
                {secondAttachment?.name}
                <DeleteButton
                  title={t("DeleteFile")}
                  type="button"
                  onClick={() => removeSecondAttachment()}
                >
                  <FontAwesomeIcon icon={solid("times")} />
                </DeleteButton>
              </>
            ) : (
              <NewFileBtn
                onClick={handleUploadSecondAttachmentClick}
                type="button"
              >
                <FontAwesomeIcon icon={solid("plus")} />
                {t("UploadSecondAttachment")}
              </NewFileBtn>
            ))}
          <input
            type="file"
            ref={secondAttachmentRef}
            onChange={(event) => setSecondAttachment(event.target.files[0])}
            hidden
            accept=".pdf"
            name="secondAttachment"
          />
        </div>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={docName}
        download={true}
        downloadDocument={downloadDocument}
      >
        <div>
          <iframe src={doc} style={{ width: "100%", height: "800px" }} />
        </div>
      </DialogModal>
    </>
  );
};

export default InputDocuments;
