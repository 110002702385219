import { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { MainContainer } from "../../../common/layout/Layout.style";
import Requests from "../../../components/external-executor-licences/requests/Requests.component";

const ProcessLicences = () => {
  const [page, setPage] = useState("1");

  useEffect(() => {
    let activePage = sessionStorage.getItem("LICENCES_ACTIVE_PAGE");
    if (activePage) {
      setPage(activePage);
    }
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
    sessionStorage.setItem("LICENCES_ACTIVE_PAGE", value);
  };

  return (
    <MainContainer>
      <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Radni zadaci" value="1" className="tab" />
            <Tab label="Moji zahtevi" value="2" />
            <Tab label="Svi zahtevi" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Requests type={4} key={1} />
        </TabPanel>
        <TabPanel value="2">
          <Requests type={3} key={2} />
        </TabPanel>
        <TabPanel value="3">
          <Requests type={2} key={3} />
        </TabPanel>
      </TabContext>
    </MainContainer>
  );
};

export default ProcessLicences;
