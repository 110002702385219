import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Client from "../../../api-services/licences/client";
import { style } from "../../../common/select-style/SelectStyle";

const SearchObjects = ({ licence, setLicence }) => {
  const { t } = useTranslation();

  const [firstLevel, setFirstLevel] = useState("");
  const [firstLvlOpts, setFirstLvlOpts] = useState([]);
  const [firstObjTitle, setFirstObjTitle] = useState("");

  const [secondLevel, setSecondLevel] = useState("");
  const [secondLvlOpts, setSecondLvlOpts] = useState([]);
  const [secondObjTitle, setSecondObjTitle] = useState("");

  const [thirdLevel, setThirdLevel] = useState("");
  const [thirdLvlOpts, setThirdLvlOpts] = useState([]);
  const [thirdObjTitle, setThirdObjTitle] = useState("");

  var client = new Client();

  useEffect(() => {
    searchObjects(1);
  }, []);

  const searchObjects = async (level) => {
    let data = {
      filter: {
        level: level,
      },
    };
    let response = await client.SearchObjects(data);
    let res = response.results.map((x) => {
      return { value: x.id, label: x.name };
    });
    setFirstLvlOpts(res);
  };

  const getObject = async (id) => {
    let response = await client.GetObject(id);
    let res = response.inverseFkParentObjectNavigation.map((x) => {
      return { value: x.id, label: x.name };
    });
    if (response.level === 1) {
      setSecondLvlOpts(res);
      setThirdLvlOpts([]);
    } else {
      if (response.level === 2) {
        setThirdLvlOpts(res);
      }
      setLicence({ ...licence, fK_Object: id, objectIds: [id] });
    }
  };

  return (
    <>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("FirstLevelObject")}</label>
          <div title={firstObjTitle}>
            <Select
              placeholder={t("SelectFromList")}
              options={firstLvlOpts}
              onChange={(e) => {
                setFirstLevel(e.value);
                setFirstObjTitle(e.label);
                setSecondLevel("");
                setSecondObjTitle("");
                setThirdLevel("");
                setThirdObjTitle("");
                getObject(e.value);
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={firstLvlOpts.filter((x) => x.value == firstLevel)}
            />
          </div>
        </div>
        {secondLvlOpts.length > 0 && (
          <div className="form-group col-md-4">
            <label>{t("SecondLevelObject")}</label>
            <div title={secondObjTitle}>
              <Select
                placeholder={t("SelectFromList")}
                options={secondLvlOpts}
                onChange={(e) => {
                  setSecondLevel(e.value);
                  setSecondObjTitle(e.label);
                  setThirdLevel("");
                  setThirdObjTitle("");
                  getObject(e.value);
                }}
                menuPortalTarget={document.body}
                styles={style}
                value={secondLvlOpts.filter((x) => x.value == secondLevel)}
              />
            </div>
          </div>
        )}
        {thirdLvlOpts.length > 0 && (
          <div className="form-group col-md-4">
            <label>{t("ThirdLevelObject")}</label>
            <div title={thirdObjTitle}>
              <Select
                placeholder={t("SelectFromList")}
                options={thirdLvlOpts}
                onChange={(e) => {
                  if (e) {
                    setThirdLevel(e.value);
                    setThirdObjTitle(e.label);
                    getObject(e.value);
                  } else {
                    setThirdLevel("");
                    setThirdObjTitle("");
                    setLicence({
                      ...licence,
                      fK_Object: secondLevel,
                      objectIds: [secondLevel],
                    });
                  }
                }}
                menuPortalTarget={document.body}
                styles={style}
                value={thirdLvlOpts.filter((x) => x.value == thirdLevel)}
                isClearable
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchObjects;
