import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async SearchLicences(data) {
    try {
      var response = await api.post(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/Search",
        data
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicence(id) {
    try {
      var response = await api.get(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/Get?id=" + id
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateLicenceForSubordinates(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/CreateForSubordinates",
        data,
        {
          headers: headers,
        }
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateLicenceForYourself(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/CreateForYourself",
        data,
        {
          headers: headers,
        }
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AcceptLicence(id) {
    try {
      var response = await api.post(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/Accept?id=" + id
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async RejectLicence(id) {
    try {
      var response = await api.post(
        baseURL + "/OutOfWorkingTimeLicenceWorkflow/Reject?id=" + id
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetEmployees(data, isBackground) {
    try {
      var response = await api.post(baseURL + "/InternalUser/Search", data, { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAllSubordinates(isBackground) {
    try {
      var response = await api.post(baseURL + "/InternalUser/GetAllSubordinates", { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetDocument(id) {
    try {
      var response = await api
        .get(baseURL + "/OutOfWorkingTimeLicenceWorkflow/GetDocument?id=" + id, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          return url;
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
