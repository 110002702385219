import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SearchUserModel } from "../../../models/UserModel";
import SearchUser from "../../../components/admin-panel/search-user/searchUser.component";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import Client from "../../../api-services/admin-panel/client";
import { MainContainer } from "../../../common/layout/Layout.style";

const InternalUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "Internal_Users";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [filterData, setFilterData] = useState(new SearchUserModel());

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("UserName"),
      accessor: "userName",
    },
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("Email"),
      accessor: "email",
    },
    {
      accessor: "buttons",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangeUser")}
              onClick={() => editUser(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("INTERNAL_USER_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.SearchInternalUsers({
      filter: storedObject ? parsedObject : filterData,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("INTERNAL_USER_FILTER", JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("INTERNAL_USER_FILTER");
    let model = new SearchUserModel();
    setFilterData(model);
    var response = await client.SearchInternalUsers({
      filter: model,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const editUser = async (id) => {
    navigate("/AdminPanel/EditInternalUser/" + id);
  };

  return (
    <MainContainer>
      <SearchUser
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
        internalUsers={true}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={"id"}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default InternalUsers;
