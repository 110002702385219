import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Client from "../../../api-services/licences/client";
import { style } from "../../../common/select-style/SelectStyle";

const SearchGroupObjects = ({ licence, setLicence }) => {
  const { t } = useTranslation();

  const [firstLevel, setFirstLevel] = useState(null);
  const [firstLvlOpts, setFirstLvlOpts] = useState([]);
  const [firstObjTitle, setFirstObjTitle] = useState("");

  const [secondLevel, setSecondLevel] = useState(null);
  const [secondLvlOpts, setSecondLvlOpts] = useState([]);

  const [thirdLevel, setThirdLevel] = useState(null);
  const [thirdLvlOpts, setThirdLvlOpts] = useState([]);

  var client = new Client();

  useEffect(() => {
    searchObjects();
  }, []);

  const getObjects = async (value, level) => {
    var firstLevelObj = firstLevel;
    var secondLevelObjs =
      secondLevel?.length > 0
        ? secondLevel.map((x) => {
            return x.value;
          })
        : null;
    var thirdLevelObjs =
      thirdLevel?.length > 0
        ? thirdLevel.map((x) => {
            return x.value;
          })
        : null;
    if (level == 1) {
      firstLevelObj = value;
      secondLevelObjs = null;
      thirdLevelObjs = null;
    } else if (level == 2) {
      secondLevelObjs =
        value.length > 0
          ? value.map((x) => {
              return x.value;
            })
          : null;
    } else {
      thirdLevelObjs =
        value.length > 0
          ? value.map((x) => {
              return x.value;
            })
          : null;
    }
    let objs =
      secondLevelObjs && thirdLevelObjs
        ? [...secondLevelObjs, ...thirdLevelObjs]
        : secondLevelObjs
        ? secondLevelObjs
        : thirdLevelObjs;
    setLicence({ ...licence, objectIds: objs });
    let data = {
      firstLevelId: firstLevelObj,
      secondLevelIds: secondLevelObjs,
      thirdLevelIds: thirdLevelObjs,
    };
    let response = await client.FilterObjectsForCreateLicence(data);
    if (response) {
      let secondObjs = response.secondLevelObjects.map((x) => {
        return { value: x.id, label: <div title={x.name}>{x.name}</div> };
      });
      setSecondLvlOpts(secondObjs);

      let thirdObjs = response.thirdLevelObjects.map((x) => {
        return { value: x.id, label: <div title={x.name}>{x.name}</div> };
      });
      setThirdLvlOpts(thirdObjs);
    }
  };

  const searchObjects = async () => {
    let data = {
      filter: {
        level: 1,
      },
    };
    let response = await client.SearchObjects(data);
    let res = response.results.map((x) => {
      return { value: x.id, label: x.name };
    });
    setFirstLvlOpts(res);
  };

  return (
    <>
      <div className="col-md-12 clearfix">
        <p className="pull-right">
          Broj odabranih objekta za koje se dozvola izdaje je{" "}
          {licence.objectIds ? licence.objectIds.length : "0"}.
        </p>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("FirstLevelObject")}</label>
          <div title={firstObjTitle}>
            <Select
              placeholder={t("SelectFromList")}
              options={firstLvlOpts}
              onChange={(e) => {
                setFirstLevel(e.value);
                setFirstObjTitle(e.label);
                setSecondLevel(null);
                setThirdLevel(null);
                getObjects(e.value, 1);
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={firstLvlOpts.filter((x) => x.value == firstLevel)}
            />
          </div>
        </div>
        {firstLevel && (
          <div className="form-group col-md-4">
            <label>{t("SecondLevelObjects")}</label>
            <Select
              placeholder={t("SelectFromList")}
              options={secondLvlOpts}
              onChange={(e) => {
                setSecondLevel(e);
                getObjects(e, 2);
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={secondLevel}
              isMulti
            />
            {(secondLevel?.length > 0 || thirdLevel?.length > 0) &&
              secondLevel?.length != secondLvlOpts.length && (
                <a
                  href="#"
                  className="pull-right"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setSecondLevel(secondLvlOpts);
                    getObjects(secondLvlOpts, 2);
                  }}
                >
                  {t("ChooseAll")}
                </a>
              )}
          </div>
        )}
        {firstLevel && (
          <div className="form-group col-md-4">
            <label>{t("ThirdLevelObjects")}</label>
            <Select
              placeholder={t("SelectFromList")}
              options={thirdLvlOpts}
              onChange={(e) => {
                setThirdLevel(e);
                getObjects(e, 3);
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={thirdLevel}
              isMulti
            />
            {thirdLevel?.length > 0 &&
              thirdLevel?.length != thirdLvlOpts.length && (
                <a
                  href="#"
                  className="pull-right"
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    setThirdLevel(thirdLvlOpts);
                    getObjects(thirdLvlOpts, 3);
                  }}
                >
                  {t("ChooseAll")}
                </a>
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchGroupObjects;
