import { dateWithoutTimezone } from "../common/custom-date-picker/CustomDatePicker.component";

class Licence {
  constructor(
    id = 0,
    foreigner = false,
    ledgerNumber = "",
    retroactive = false,
    companyName = "",
    requestDescription = "",
    status = 0,
    visitDate = dateWithoutTimezone(new Date()),
    firstAndLastNameOfThePersonWhoAcceptsVisit = "",
    firstAndLastNameOfThePersonWhoAnnouncingTheVisit = "",
    purposeOfVisitDescription = "",
    numberOfPersons = 0,
    firstAndLastNameOfThePersonVisiting = "",
    licenceValidityDateTo = dateWithoutTimezone(new Date()),
    licenceValidityDateFrom = dateWithoutTimezone(new Date()),
    firstAndLastNameOfTheExpertPerson = "",
    contactPhoneNumberOfTheExpertPerson = "",
    vehicleMakeModelAndPlateNumber = "",
    isTheBzrAgreementAnIntegralPartOfTheContract = false,
    fkSapcontract = null,
    fkObject = null,
    fkLicenceType = null,
    fkAdditionalLicence = null,
    privilageForTheLoggedUser = null,
    objectIds = []
  ) {
    this.id = id;
    this.foreigner = foreigner;
    this.ledgerNumber = ledgerNumber;
    this.retroactive = retroactive;
    this.companyName = companyName;
    this.requestDescription = requestDescription;
    this.status = status;
    this.visitDate = visitDate;
    this.firstAndLastNameOfThePersonWhoAcceptsVisit =
      firstAndLastNameOfThePersonWhoAcceptsVisit;
    this.firstAndLastNameOfThePersonWhoAnnouncingTheVisit =
      firstAndLastNameOfThePersonWhoAnnouncingTheVisit;
    this.purposeOfVisitDescription = purposeOfVisitDescription;
    this.numberOfPersons = numberOfPersons;
    this.firstAndLastNameOfThePersonVisiting =
      firstAndLastNameOfThePersonVisiting;
    this.licenceValidityDateTo = licenceValidityDateTo;
    this.licenceValidityDateFrom = licenceValidityDateFrom;
    this.firstAndLastNameOfTheExpertPerson = firstAndLastNameOfTheExpertPerson;
    this.contactPhoneNumberOfTheExpertPerson =
      contactPhoneNumberOfTheExpertPerson;
    this.vehicleMakeModelAndPlateNumber = vehicleMakeModelAndPlateNumber;
    this.isTheBzrAgreementAnIntegralPartOfTheContract =
      isTheBzrAgreementAnIntegralPartOfTheContract;
    this.fkSapcontract = fkSapcontract;
    this.fkObject = fkObject;
    this.fkLicenceType = fkLicenceType;
    this.fkAdditionalLicence = fkAdditionalLicence;
    this.privilageForTheLoggedUser = privilageForTheLoggedUser;
    this.objectIds = objectIds;
  }
}

class EmployeeLicence {
  constructor(
    id = 0,
    applicantEmployeeIds = [],
    description = "",
    comment = "",
    organizationalUnit = "",
    dateFrom = dateWithoutTimezone(new Date()),
    dateTo = dateWithoutTimezone(new Date()),
    purposeOfComing = "",
    fK_Object = null,
    outOfWorkingTimeLicenceWorkflowDocuments = []
  ) {
    this.id = id;
    this.applicantEmployeeIds = applicantEmployeeIds;
    this.description = description;
    this.comment = comment;
    this.organizationalUnit = organizationalUnit;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.purposeOfComing = purposeOfComing;
    this.fK_Object = fK_Object;
    this.outOfWorkingTimeLicenceWorkflowDocuments =
      outOfWorkingTimeLicenceWorkflowDocuments;
  }
}

class AdditionalLicence {
  constructor(
    id = 0,
    type = 0,
    dateFrom = dateWithoutTimezone(new Date()),
    dateTo = dateWithoutTimezone(new Date()),
    workManager = "",
    expertPerson = "",
    responsiblePerson = "",
    assistentOfResponsiblePerson = "",
    personWhoIssuedTheLicence = "",
    descriptionOfWorks = "",
    fkLicenceCase = 0,
    fkLicenceWorkflow = 0
  ) {
    this.id = id;
    this.type = type;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.workManager = workManager;
    this.expertPerson = expertPerson;
    this.responsiblePerson = responsiblePerson;
    this.assistentOfResponsiblePerson = assistentOfResponsiblePerson;
    this.personWhoIssuedTheLicence = personWhoIssuedTheLicence;
    this.descriptionOfWorks = descriptionOfWorks;
    this.fkLicenceCase = fkLicenceCase;
    this.fkLicenceWorkflow = fkLicenceWorkflow;
  }
}

class WorkManager {
  constructor(
    oldWorkManager = "",
    newWorkManager = "",
    reasonForChange = "",
    note = "",
    responsiblePerson = "",
    date = dateWithoutTimezone(new Date()),
    fkAdditionalLicence = null
  ) {
    this.oldWorkManager = oldWorkManager;
    this.newWorkManager = newWorkManager;
    this.reasonForChange = reasonForChange;
    this.note = note;
    this.responsiblePerson = responsiblePerson;
    this.date = date;
    this.fkAdditionalLicence = fkAdditionalLicence;
  }
}

class LicenceWorkflowPrivilege {
  constructor(canWorkOnActiveStep = false) {
    this.canWorkOnActiveStep = canWorkOnActiveStep;
  }
}

class SearchLicenceModel {
  constructor(
    licenceWorkflowFilterType = 1,
    companyName = "",
    ledgerNumber = "",
    statuses = null,
    objectIds = null,
    objectName = "",
    sapcontractIds = null,
    licenceTypeIds = null,
    licenceTypeName = "",
    firstAndLastNameOfTheExpertPerson = "",
    licenceValidityDateFrom = null,
    licenceValidityDateTo = null
  ) {
    this.licenceWorkflowFilterType = licenceWorkflowFilterType;
    this.companyName = companyName;
    this.ledgerNumber = ledgerNumber;
    this.statuses = statuses;
    this.objectIds = objectIds;
    this.objectName = objectName;
    this.sapcontractIds = sapcontractIds;
    this.licenceTypeIds = licenceTypeIds;
    this.licenceTypeName = licenceTypeName;
    this.firstAndLastNameOfTheExpertPerson = firstAndLastNameOfTheExpertPerson;
    this.licenceValidityDateFrom = licenceValidityDateFrom;
    this.licenceValidityDateTo = licenceValidityDateTo;
  }
}

class LicenceCaseView {
  constructor(
    id = 0,
    name = "",
    activeLicenceWorkflows = null,
    complitedLicenceWorkflows = null,
    activePersons = null,
    complitedPersons = null,
    activeEquipments = null,
    complitedEquipments = null,
    activeAdditionalLicences = null,
    complitedAdditionalLicences = null
  ) {
    this.id = id;
    this.name = name;
    this.activeLicenceWorkflows = activeLicenceWorkflows;
    this.complitedLicenceWorkflows = complitedLicenceWorkflows;
    this.activePersons = activePersons;
    this.complitedPersons = complitedPersons;
    this.activeEquipments = activeEquipments;
    this.complitedEquipments = complitedEquipments;
    this.activeAdditionalLicences = activeAdditionalLicences;
    this.complitedAdditionalLicences = complitedAdditionalLicences;
  }
}

export {
  Licence,
  EmployeeLicence,
  AdditionalLicence,
  WorkManager,
  LicenceWorkflowPrivilege,
  SearchLicenceModel,
  LicenceCaseView,
};
