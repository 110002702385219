import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
export const listOfApps = [
    {
        name: "BZR služba",
        path: "/BZRService",
        icon:  <FontAwesomeIcon icon={solid("shield")} />,
        roles: ["BzrOfficer"]
    },
    {
        name: "Pretraga i pregled ugovora",
        path: "/Contracts",
        icon:  <FontAwesomeIcon icon={solid("file-text")} />,
        roles: ["BzrOfficer", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantExternalExecutors", "ApplicantRetroactiveIssuanceOfLicenses"]
    },
    {
        name: "Upravljanje dozvolama za ulazak eksternih izvršilaca",
        path: "/ExternalExecutorLicences",
        icon:  <FontAwesomeIcon icon={solid("users")} />,
        roles: ["ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantRetroactiveIssuanceOfLicenses", "DefenceAndSecurityOfficer"]
    },
    {
        name: "Upravljanje dozvolama za ulazak zaposlenih van radnog vremena",
        path: "/EmployeeLicences",
        icon:  <FontAwesomeIcon icon={solid("clock")} />,
        roles: ["ApplicantOutOfWorkingTimeLicence", "ApplicantEmployee"]
    },
    {
        name: "Admin panel",
        path: "/AdminPanel",
        icon:  <FontAwesomeIcon icon={solid("cogs")} />,
        roles: ["Administrator", "AdministratorOfExternalExecutorAccounts"]
    },
]