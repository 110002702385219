import { createContext, useEffect, useState } from "react";
import Client from "../api-services/admin-panel/client";
import UserService from "../utils/authentification/keycloak";
import useAppUrl from "../hooks/useAppUrl";

const UserRolesContext = createContext(true);

export const UserRolesProvider = ({ children }) => {
  const { appPath } = useAppUrl();
  const [userRoles, setUserRoles] = useState();

  var client = new Client();

  useEffect(() => {
    if (UserService.isLoggedIn) {
      getUserRoles();
    }
  }, [appPath]);

  const getUserRoles = async () => {
    var id = UserService.getEmployeeId();
    if (id) {
      var response = await client.GetUserRoles(id, true);
      if (response && response != "Error") {
        let roles = response.map((x) => {
          return x.name;
        });
        setUserRoles(roles);
      }
    }
  };

  return (
    <UserRolesContext.Provider value={{ userRoles, setUserRoles }}>
      {children}
    </UserRolesContext.Provider>
  );
};

export default UserRolesContext;
