import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmployeeLicence } from "../../../models/LicenceModel";
import PreviewEmployeeLicence from "../../../components/employee-licences/preview-licence/previewEmployeeLicence.component";
import Client from "../../../api-services/employee-licences/client";

const PreviewLicenceForEmployee = () => {
  const { id } = useParams();
  const [licence, setLicence] = useState(new EmployeeLicence());

  var client = new Client();

  useEffect(() => {
    getLicence(id);
  }, []);

  const getLicence = async (id) => {
    let data = await client.GetLicence(id);
    setLicence(data);
  };

  return (
    <PreviewEmployeeLicence data={licence} forYourself={false} />
  );
};

export default PreviewLicenceForEmployee;
