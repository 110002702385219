import React from 'react';

const PersonEquipmentEnum = {
  1: "Aktivan", // Active
  2: "Neaktivan", // Inactive
  4: "Suspendovan", // Suspended
};

const PersonEquipmentStatus = ({ value }) => {
  return <span>{PersonEquipmentEnum[value]}</span>;
};

export default PersonEquipmentStatus;