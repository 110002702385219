import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import SearchLicence from "../../../components/employee-licences/search-licence/searchLicence.component";
import { MainContainer } from "../../../common/layout/Layout.style";
import Client from "../../../api-services/employee-licences/client";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import LicenceStatus from "../../../common/employee-licence-request-status/EmployeeLicenceRequestStatus";
import { useNavigate } from "react-router-dom";

const LicencesPreview = ({ forYourself }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "Licences";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    firstOrLastNameOfApplicant: "",
    organizationalUnit: "",
    tabFilter: forYourself ? 0 : 1,
  });
  const filterName = forYourself ? "MY_LICENCES_FILTER" : "LICENCES_FILTER";

  const columns = [
    {
      Header: "Objekat",
      accessor: "objectName",
      Cell: ({ row }) => {
        return row.original.level2ObjectName
          ? row.original.level2ObjectName +
              (row.original.level3ObjectName
                ? " -> " + row.original.level3ObjectName
                : "")
          : row.original.objectName;
      },
    },
    {
      Header: "Organizaciona jedinica",
      accessor: "organizationalUnit",
    },
    {
      Header: "Ime i prezime osobe za koju se traži odobrenje",
      accessor: "firstAndLastNameOfAplicant",
    },
    {
      Header: "Ime i prezime neposrednog rukovodioca",
      accessor: "firstAndLastNameOfSupervisor",
    },
    {
      Header: "Datum početka važnosti dozvole",
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.dateFrom
              ? format(new Date(row.original.dateFrom), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Datum prestanka važnosti dozvole",
      accessor: "dateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.dateTo
              ? format(new Date(row.original.dateTo), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Opis zahteva",
      accessor: "description",
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ row }) => {
        return <LicenceStatus value={row.original.status} />;
      },
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary"
              title={row.original.status == 2 ? t("PreviewLicence") : t("PreviewRequest")}
              onClick={() => previewLicence(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem(filterName);
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.SearchLicences({
      filter: storedObject ? parsedObject : filterData,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem(filterName, JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem(filterName);
    let data = {
      firstOrLastNameOfApplicant: "",
      organizationalUnit: "",
      tabFilter: forYourself ? 0 : 1,
    };
    setFilterData(data);
    var response = await client.SearchLicences({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const previewLicence = async (id) => {
    if (forYourself) {
      navigate("/EmployeeLicences/MyLicences/PreviewLicenceForYourself/" + id);
    } else {
      navigate("/EmployeeLicences/PreviewLicenceForEmployee/" + id);
    }
  };

  return (
    <MainContainer>
      <SearchLicence
        filter={filterData}
        setFilter={setFilterData}
        searchData={searchData}
        resetFilter={resetFilters}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default LicencesPreview;
