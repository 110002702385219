import styled from "styled-components";

export const SubHeadingLicences = styled.div`
  margin: 10px;
  margin-bottom: 35px;
  margin-top: 0;
  height: 20px;
  display: inline-block;
  border-color: #e81f27;
  font-size: 24px;
  font-weight: initial;
  &:after {
    content: "";
    display: block;
    position: relative;
    border-top: 4px solid #e81f27;
    border-right: 8px solid transparent;
    border-bottom: 10px solid transparent;
    width: 150%;
  }
`;
