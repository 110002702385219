import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { format } from "date-fns";
import Table from "../../common/table/Table.component";
import Client from "../../api-services/profile/client";
import DialogModal from "../../common/modal/Modal.component";
import ButtonWithConfirmation from "../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CreateReplacement from "../../components/profile/create-replacement/createReplacement.component";
import { MainContainer } from "../../common/layout/Layout.style";

const Profile = () => {
  const { t } = useTranslation();
  const tableName = "User_Replacements";
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [subordinates, setSubordinates] = useState([]);
  const [replacements, setReplacements] = useState([]);
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "replacement.firstName",
    },
    {
      Header: t("LastName"),
      accessor: "replacement.lastName",
    },
    {
      Header: t("Email"),
      accessor: "replacement.email",
    },
    {
      Header: t("NameAndLastName"),
      accessor: "employee.firstAndLastName",
    },
    {
      Header: t("Email"),
      accessor: "employee.email",
    },
    {
      Header: "Trajna zamena",
      accessor: "permanent",
      Cell: ({ row }) => {
        return <div>{row.original.permanent ? t("Yes") : t("No")}</div>;
      },
    },
    {
      Header: "Datum početka važenja zamene",
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {!row.original.permanent
              ? format(new Date(row.original.dateFrom), "dd.MM.yyyy")
              : "/"}
          </div>
        );
      },
    },
    {
      Header: "Datum prestanka važenja zamene",
      accessor: "dateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {!row.original.permanent
              ? format(new Date(row.original.dateTo), "dd.MM.yyyy")
              : "/"}
          </div>
        );
      },
    },
    {
      accessor: "buttons",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <ButtonWithConfirmation
              className="btn btn-danger table-btn"
              title={t("DeleteReplacement")}
              onClick={() => {
                deleteReplacement(row.original.id);
              }}
              contentText={"Zamena će biti trajno izbrisana."}
              handleYesText={t("Delete")}
            >
              <FontAwesomeIcon icon={solid("remove")} />
            </ButtonWithConfirmation>
          </>
        );
      },
    },
  ];

  const subordinatesColumns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("NameAndLastName"),
      accessor: "firstAndLastName",
    },
    {
      Header: t("UserName"),
      accessor: "userName",
    },
    {
      Header: t("Email"),
      accessor: "email",
    },
  ];

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    getUser();
    getAllReplacements();
    getEmployeeReplacements();
  }, []);

  const getUser = async () => {
    let response = await client.GetUser();
    setUser(response);
    setSubordinates(response.subordinates);
  };

  const getAllReplacements = async () => {
    let response = await client.GetAllReplacements();
    setData(response);
  };

  const getEmployeeReplacements = async () => {
    let response = await client.GetEmployeeReplacements();
    setReplacements(response);
  };

  const deleteReplacement = async (id) => {
    let response = await client.DeleteReplacement(id);
    if (response == "Error") {
    } else {
      let replacements = data.filter((x) => x.id !== id);
      setData(replacements);
    }
  };

  const onCreate = async (response) => {
    setData([...data, response]);
  };

  return (
    <>
      <MainContainer>
        <div className="form-group col-md-3">
          <label>Korisnicko ime</label>
          <input
            type="text"
            className="form-control"
            value={user.userName}
            readOnly
          />
        </div>
        <div className="form-group col-md-3">
          <label>Ime</label>
          <input
            type="text"
            className="form-control"
            value={user.firstName}
            readOnly
          />
        </div>
        <div className="form-group col-md-3">
          <label>Prezime</label>
          <input
            type="text"
            className="form-control"
            value={user.lastName}
            readOnly
          />
        </div>
        <div className="form-group col-md-3">
          <label>E-mail</label>
          <input
            type="text"
            className="form-control"
            value={user.email}
            readOnly
          />
        </div>
        <div className="form-group col-md-3">
          <label>Nadređeni</label>
          {user.superior ?
            <input
              type="text"
              className="form-control"
              value={user.superior.firstAndLastName + " (" + user.superior.email + ")"}
              readOnly
            />
            :
            <div>Nemate nadređenog.</div>
          }
        </div>
        <div className="form-group">
          <label>Podređeni</label>
          {subordinates.length > 0 ? (
            <Table
              columns={subordinatesColumns}
              data={subordinates}
              tableName={"Subordinates"}
              rememberFilter={false}
              hiddenColumn={"id"}
            />
          ) : (
            <div>Nijednom zaposlenom niste nadređeni.</div>
          )}
        </div>
        <div className="form-group">
          <label>Zaposleni kojima ste zamena</label>
          {replacements.length > 0 ? (
            <Table
              columns={columns}
              data={replacements}
              tableName={"Replacements"}
              rememberFilter={false}
              hiddenColumn={[
                "id",
                "replacement.firstName",
                "replacement.lastName",
                "replacement.email",
                "buttons",
              ]}
            />
          ) : (
            <div>Nijednom zaposlenom niste zamena.</div>
          )}
        </div>
        <div className="form-group">
          <label>Vaše zamene</label>
          {data.length > 0 ? (
            <Table
              columns={columns}
              data={data}
              tableName={tableName}
              rememberFilter={false}
              hiddenColumn={[
                "id",
                "employee.firstAndLastName",
                "employee.email",
              ]}
            />
          ) : (
            <div>Nemate nijednu zamenu.</div>
          )}
          <br />
          <button
            type="button"
            className="btn btn-primary"
            onClick={ShowDialog}
          >
            Dodaj zamenu
          </button>
        </div>
        <DialogModal show={show} onHide={HideDialog} title={"Dodavanje zamene"}>
          <CreateReplacement hide={HideDialog} onCreate={onCreate} />
        </DialogModal>
      </MainContainer>
    </>
  );
};

export default Profile;
