import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogModal from "../../../common/modal/Modal.component";
import CreateAdditionalLicence from "../create-additional-licence/createAdditionalLicence.component";
import { format } from "date-fns";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Client from "../../../api-services/licences/client";
import { AdditionalLicence } from "../../../models/LicenceModel";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import AdditionalLicenceStatus from "../../../common/additional-licence-status/AdditionalLicenceStatus";
import api from "../../../api";
import { env } from "../../../env";
import { dateWithoutTimezone } from "../../../common/custom-date-picker/CustomDatePicker.component";

var baseURL = env.REACT_APP_API_URL_AAMS;

const AdditionalLicences = ({
  preview,
  canAdd,
  isD2,
  data,
  baseLicence,
}) => {
  const { t } = useTranslation();
  const [d2, setD2] = useState(isD2);
  const [showAdditional, setShowAdditional] = useState(false);
  const [d2Licence, setD2Licence] = useState([]);
  const [d3Licence, setD3Licence] = useState([]);
  const [licence, setLicence] = useState(new AdditionalLicence());
  const [edit, setEdit] = useState(false);

  const [objectsError, setObjectsError] = useState(false);
  const [personsError, setPersonsError] = useState(false);

  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [showDoc, setShowDoc] = useState(false);

  const ShowDocument = () => setShowDoc(true);
  const HideDocument = () => setShowDoc(false);

  const columns = [
    {
      Header: t("LicenceNumber"),
      accessor: "ledgerNumber",
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ value }) => {
        return <AdditionalLicenceStatus value={value} />;
      },
    },
    {
      Header: t("Objects"),
      accessor: "additionalLicenceObjects",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.additionalLicenceObjects?.map((x, index) => {
              return (
                <div key={index} style={{ whiteSpace: "nowrap" }}>
                  {x.level2ObjectName +
                    (x.level3ObjectName ? " -> " + x.level3ObjectName : "") +
                    (row.original.additionalLicenceObjects.length != index + 1
                      ? ", "
                      : "")}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: t("Persons"),
      accessor: "additionalLicencePeople",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.additionalLicencePeople?.map((x, index) => {
              return (
                x.firstName +
                " " +
                x.lastName +
                (row.original.additionalLicencePeople.length != index + 1
                  ? ", "
                  : "")
              );
            })}
          </div>
        );
      },
    },
    {
      Header: t("DateFrom"),
      accessor: "dateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.dateFrom
              ? format(new Date(row.original.dateFrom), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("DateTo"),
      accessor: "dateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.dateTo
              ? format(new Date(row.original.dateTo), "dd.MM.yyyy")
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Opis posla",
      accessor: "descriptionOfWorks",
    },
    {
      Header:
        t("firstAndLastNameOfTheExpertPerson"),
      accessor: "expertPerson",
    },
    {
      Header: "Odgovorno lice - rukovodilac radova",
      accessor: "workManager",
    },
    {
      Header: "Odgovorna lica - izvođači radova",
      accessor: "responsiblePerson",
    },
    {
      Header: "Izvođači radova",
      accessor: "assistentOfResponsiblePerson",
    },
    {
      Header: "Dozvolu izdao",
      accessor: "personWhoIssuedTheLicence",
    },
    {
      accessor: "preview",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <RenderOnRole roles={["AuthorizedPersonForSecondLevelObjects"]}>
            <button
              type="button"
              className="btn btn-primary table-btn"
              title={t("PreviewLicence")}
              onClick={() => {
                setEdit(true);
                setObjectsError(false);
                setPersonsError(false);
                getLicence(row.original.id);
                ShowAdditionalLicence();
              }}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
            {preview ? (
              <ButtonWithConfirmation
                className="btn btn-danger table-btn"
                title={t("StornoLicence")}
                onClick={() => {
                  cancelAdditionalLicence(row.original.id);
                }}
                contentText={"Dozvola će biti trajno stornirana."}
                handleYesText={t("Accept")}
                disabled={row.original.status == 2}
              >
                <FontAwesomeIcon icon={solid("ban")} />
              </ButtonWithConfirmation>
            ) : (
              <ButtonWithConfirmation
                className="btn btn-danger table-btn"
                title={t("DeleteLicence")}
                onClick={() => removeAdditionalLicence(row.original.id)}
                contentText={"Dozvola će biti trajno obrisana."}
                handleYesText={t("Accept")}
              >
                <FontAwesomeIcon icon={solid("remove")} />
              </ButtonWithConfirmation>
            )}
          </RenderOnRole>
        );
      },
    },
    {
      accessor: "download",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title="Pregled generisane dozvole"
              onClick={() => {
                getAdditionalLicenceDocument(
                  row.original.id,
                  row.original.ledgerNumber
                );
              }}
            >
              <FontAwesomeIcon icon={solid("eye")} />
            </button>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("OwnResponsibilityDeclaration")}
              onClick={() => {
                getStatement(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("user-pen")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    if (data) {
      if (isD2) {
        setD2Licence(data);
      } else {
        setD3Licence(data);
      }
    }
  }, [data]);

  const ShowAdditionalLicence = () => setShowAdditional(true);
  const HideAdditionalLicence = () => setShowAdditional(false);

  const getLicence = async (id) => {
    let response = await client.GetAdditionalLicence(id);
    setLicence(response);
  };

  const addAdditionalLicence = async (licenceData) => {
    let objsErr =
      !licenceData.additionalLicenceObjectIds ||
      licenceData.additionalLicenceObjectIds.length < 1;
    setObjectsError(objsErr);
    let personsErr =
      !licenceData.additionalLicencePersonIds ||
      licenceData.additionalLicencePersonIds.length < 1;
    setPersonsError(personsErr);

    if (!objsErr && !personsErr) {
      let data = {
        ...licenceData,
        type: d2 ? 1 : 2,
        fkLicenceWorkflow: baseLicence.id,
        fkLicenceCase: baseLicence.fkLicenceCase,
        additionalLicenceObjectIds: licenceData.additionalLicenceObjectIds.map(
          (x) => {
            return x.value;
          }
        ),
        additionalLicencePersonIds: licenceData.additionalLicencePersonIds.map(
          (x) => {
            return x.value;
          }
        ),
      };
      if (data.id && data.id != 0) {
        var response = await client.UpdateAdditionalLicence(data);
      } else {
        var response = await client.CreateAdditionalLicence(data);
      }
      if (response && response != "Error") {
        if (d2) {
          handleAdditionalLicence(response, d2Licence, setD2Licence);
        } else {
          handleAdditionalLicence(response, d3Licence, setD3Licence);
        }
      }
    }
  };

  const handleAdditionalLicence = (response, data, setData) => {
    let index = data.findIndex((x) => x.id == response.id);
    if (index > -1) {
      let newData = [...data];
      newData[index] = response;
      setData(newData);
    } else {
      setData([...data, response]);
    }
    HideAdditionalLicence();
  };

  const removeAdditionalLicence = async (id) => {
    let data = {
      id: id,
      licenceWorkflowId: baseLicence.id,
    };
    let response = await client.RemoveAdditionalLicence(data);
    if (response != "Error") {
      if (d2) {
        let d2Data = d2Licence.filter((x) => x.id != id);
        setD2Licence(d2Data);
      } else {
        let d3Data = d3Licence.filter((x) => x.id != id);
        setD3Licence(d3Data);
      }
    }
  };

  const cancelAdditionalLicence = async (id) => {
    let response = await client.CancelAdditionalLicence(id);
    if (response != "Error") {
      if (d2) {
        handleAdditionalLicence(response, d2Licence, setD2Licence);
      } else {
        handleAdditionalLicence(response, d3Licence, setD3Licence);
      }
    }
  };

  const getAdditionalLicenceDocument = (id, ledgerNumber) => {
    let url =
      baseURL +
      "/LicenceWorkflow/GetAdditionalLicenceDocument?additionalLicenceId=" +
      id;
    let name = (d2 ? "D2 dozvola " : "D3 dozvola ") + ledgerNumber;
    getDocument(url, name);
  };

  const getStatement = (id) => {
    let url =
      baseURL +
      "/LicenceWorkflow/GetStatementOfOwnResponsibilityDocument?additionalLicenceId=" +
      id;
    getDocument(url, t("OwnResponsibilityDeclaration"));
  };

  const getDocument = async (urlName, fileName) => {
    let response = await api.get(urlName, {
      responseType: "blob",
    });
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(fileName);
    ShowDocument();
  };

  const downloadDocument = (url, name) => {
    const link = document.createElement("a");
    link.href = url ? url : doc;
    link.download = name ? name : docName;
    link.click();
  };

  const getAdditionalLicenceWithValidDate = () => {
    const dateFrom = new Date(baseLicence.licenceValidityDateFrom) > new Date() ? baseLicence.licenceValidityDateFrom : dateWithoutTimezone(new Date());
    const dateTo = baseLicence.licenceValidityDateTo;

    const obj = new AdditionalLicence();
    obj.dateFrom = dateFrom;
    obj.dateTo = dateTo;
    return obj;
  }

  return (
    <>
      {d2 ? (
        <>
          <h5>
            {t("D2Licences")} &nbsp;
            {canAdd && (
              <RenderOnRole roles={["AuthorizedPersonForSecondLevelObjects"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setEdit(false);
                    setObjectsError(false);
                    setPersonsError(false);
                    setD2(true);
                    setLicence(getAdditionalLicenceWithValidDate());
                    ShowAdditionalLicence();
                  }}
                >
                  Dodaj D2 dozvolu
                </button>
              </RenderOnRole>
            )}
          </h5>
          {d2Licence.length > 0 ? (
            <div>
              <Table
                columns={columns}
                data={d2Licence}
                tableName={"D2_Licences"}
                rememberFilter={false}
                hiddenColumn={!canAdd && ["preview", "download"]}
              />
            </div>
          ) : (
            <span>Nema izdatih D2 dozvola.</span>
          )}
        </>
      ) : (
        <>
          <h5>
            {t("D3Licences")} &nbsp;
            {canAdd && (
              <RenderOnRole roles={["AuthorizedPersonForSecondLevelObjects"]}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setEdit(false);
                    setObjectsError(false);
                    setPersonsError(false);
                    setD2(false);
                    setLicence(getAdditionalLicenceWithValidDate());
                    ShowAdditionalLicence();
                  }}
                >
                  Dodaj D3 dozvolu
                </button>
              </RenderOnRole>
            )}
          </h5>
          {d3Licence.length > 0 ? (
            <div>
              <Table
                columns={columns}
                data={d3Licence}
                tableName={"D3_Licences"}
                rememberFilter={false}
                hiddenColumn={[
                  "workManager",
                  "responsiblePerson",
                  "assistentOfResponsiblePerson",
                  "personWhoIssuedTheLicence",
                  !canAdd && "preview",
                  !canAdd && "download",
                ]}
              />
            </div>
          ) : (
            <span>Nema izdatih D3 dozvola.</span>
          )}
        </>
      )}
      <DialogModal
        show={showAdditional}
        onHide={HideAdditionalLicence}
        title={
          (edit ? "Pregled " : "Dodavanje ") + (d2 ? "D2 " : "D3 ") + "dozvole"
        }
      >
        <CreateAdditionalLicence
          addLicence={addAdditionalLicence}
          data={licence}
          baseLicence={baseLicence}
          d2={d2}
          preview={edit && preview}
          complited={preview}
          objectsError={objectsError}
          setObjectsError={setObjectsError}
          personsError={personsError}
          setPersonsError={setPersonsError}
        />
      </DialogModal>
      <DialogModal
        show={showDoc}
        onHide={HideDocument}
        title={!preview ? t("LicenceInfoWarning") : docName}
        download={true}
        downloadDocument={downloadDocument}
        warning={!preview}
      >
        <div>
          <iframe src={doc} style={{ width: "100%", height: "800px" }} />
        </div>
      </DialogModal>
    </>
  );
};

export default AdditionalLicences;
