import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LicenceForm from "../../../components/external-executor-licences/licence-form/LicenceForm.component";
import { Licence } from "../../../models/LicenceModel";
import SAPContract from "../../../components/bzr-service/sap-contract/sapContract.component";
import SearchPersons from "../../../components/external-executor-licences/search-persons/searchPersons.component";
import SearchEquipments from "../../../components/external-executor-licences/search-equipments/searchEquipments.component";
import {
  StatusForValidation,
  StatusForThirdStep,
  StatusForAcception,
  StatusForRejection,
  StatusAccepted,
  StatusForAdditionalLicences,
} from "../../../common/licence-request-status/LicenceRequestStatus";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import Client from "../../../api-services/licences/client";
import DialogModal from "../../../common/modal/Modal.component";
import { MainContainer } from "../../../common/layout/Layout.style";
import History from "../../../components/history/history.component";
import api from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { env } from "../../../env";
import AdditionalLicences from "../../../components/external-executor-licences/additional-licences/additionalLicences.component";
import Attachments from "../../../components/external-executor-licences/attachments/attachments.component";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import LicenceStatus from "../../../common/licence-request-status/LicenceRequestStatus";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";

var baseURL = env.REACT_APP_API_URL_AAMS;

const PreviewLicence = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [licence, setLicence] = useState(new Licence());
  const [comment, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [show, setShow] = useState(false);
  const [history, setHistory] = useState([]);
  const [rejecting, setRejecting] = useState(true);

  const [attachments, setAttachments] = useState([]);
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [showDoc, setShowDoc] = useState(false);
  const [showComm, setShowComm] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [d2Licences, setD2Licences] = useState([]);
  const [d3Licences, setD3Licences] = useState([]);
  const [persons, setPersons] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [objectLevel, setObjectLevel] = useState(2);
  const [licenceType, setLicenceType] = useState(null);
  const [licenceRenewal, setLicenceRenewal] = useState(false);
  const [licenceRenewalName, setLicenceRenewalName] = useState("");
  const [licenceRenewalId, setLicenceRenewalId] = useState("");
  const [isLicenceValid, setIsLicenceValid] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailsError, setEmailsError] = useState(false);

  const docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const schema = {
    email: Joi.string().email().optional(),
    comment: Joi.string().required()
  };

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);
  const ShowDocument = () => setShowDoc(true);
  const HideDocument = () => setShowDoc(false);
  const ShowComment = () => setShowComm(true);
  const HideComment = () => setShowComm(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);

  useEffect(() => {
    getLicence(id);
  }, []);

  const getLicence = async (id) => {
    let data = await client.GetLicenceWorkflowBase(id);
    let objsLevel = data.licenceObjectsView?.map(x => {
      return x.level;
    });
    let level = objsLevel.includes(3) ? 3 : 2;
    setObjectLevel(level);
    setLicence(data);
    setAttachmentsPreview(data.licenceWorkflowDocuments.filter((x) => x.documentType == 1));
    setLicenceType(data.licenceType);
    if (data.parentNavigations.length > 0) {
      setLicenceRenewal(true);
      setLicenceRenewalName(data.parentNavigations[0].ledgerNumber);
      setLicenceRenewalId(data.parentNavigations[0].id);
    }
    checkLicenceValidity(data.licenceValidityDateTo);
    getAdditionalLicences(id);
    getHistory(id);
    getPersons(id);
    getEquipments(id);
  };

  const checkLicenceValidity = (to) => {
    let dateTo = new Date(to);
    let currentDate = new Date();
    if (currentDate <= dateTo) {
      setIsLicenceValid(true);
    } else {
      setIsLicenceValid(false);
    }
  }

  const getAdditionalLicences = async (id) => {
    let data = await client.GetAdditionalLicences(id);
    let d2LicencesData = data.filter((x) => x.type == 1);
    setD2Licences(d2LicencesData);
    let d3LicencesData = data.filter((x) => x.type == 2);
    setD3Licences(d3LicencesData);
  }

  const getHistory = async (id) => {
    let data = await client.GetLicenceHistory(id);
    setHistory(data);
    let last = data[data.length - 1];
    if (last.comment) {
      setRejectComment(last.comment);
      ShowComment();
    }
  };

  const getPersons = async (id) => {
    let data = await client.GetLicencePersons(id);
    setPersons(data);
  }

  const getEquipments = async (id) => {
    let data = await client.GetLicenceEquipments(id);
    setEquipments(data);
  }

  const checkAcceptForThirdStep = async () => {
    let canAccept = await client.AcceptCheck(licence.id, true);
    if (canAccept) {
      ShowAlertDialog();
    } else {
      accept(false);
    }
  };

  const accept = async (acceptThird) => {
    let data = {
      id: licence.id,
    };
    var formData = new FormData();
    serializeObjectIntoFormData(formData, data);

    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].guid)
        formData.append(
          "licenceWorkflowDocuments[" + i + "].guid",
          attachments[i].guid
        );
      else
        formData.append(
          "licenceWorkflowDocuments[" + i + "].file",
          attachments[i]
        );

      formData.append(
        "licenceWorkflowDocuments[" + i + "].name",
        attachments[i].name
      );
      formData.append("licenceWorkflowDocuments[" + i + "].documentType", 1);
    }

    if (StatusForValidation.includes(licence.status)) {
      var response = await client.ForwardToTheSecondLevel(formData);
    } else if (StatusForThirdStep.includes(licence.status) && objectLevel == 3 && !acceptThird) {
      var response = await client.ForwardToTheThirdLevel(formData);
    } else if (StatusForAcception.includes(licence.status) || acceptThird) {
      var response = await client.Accept(formData);
    }

    if (response !== "Error") {
      navigate("/ExternalExecutorLicences/ProcessLicence");
    }
  };

  const reject = async () => {
    let data = {
      id: licence.id,
      comment: comment,
    };
    let response = await client.Reject(data);
    if (response !== "Error") {
      HideDialog();
      navigate("/ExternalExecutorLicences/ProcessLicence");
    }
  };

  const checkRejectValidation = () => {
    const errorsResult = validateForm({ comment: comment }, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      ShowAlertDialog();
    }
  }

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, null, errors, schema, t);
    if (prop == "email") {
      setEmail(value);
    } else {
      setComment(value);
    }
    setErrors(result.errors);
  };

  const sendNotification = async () => {
    const emailsErr = emails.length < 1;
    setEmailsError(emailsErr);

    if (!emailsErr) {
      let data = {
        licenceWorkflowId: licence.id,
        emails: emails,
        comment: comment,
      };
      let response = await client.NotifyObject(data);
      if (response != "Error") {
        HideDialog();
        setComment("");
        setEmail("");
        setEmails([]);
      }
    }
  };

  const getDocument = async (urlName, fileName) => {
    let response = await api.get(urlName, {
      responseType: "blob",
    });
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    if (docTypes.includes(response.data.type)) {
      downloadDocument(url, fileName);
    } else {
      setDoc(url);
      setDocName(fileName);
      ShowDocument();
    }
  };

  const downloadDocument = (url, name) => {
    const link = document.createElement("a");
    link.href = url ? url : doc;
    link.download = name ? name : docName;
    link.click();
  };

  const generateLicenceDocument = async () => {
    let url =
      baseURL +
      "/LicenceWorkflow/GenerateLicenceDocument?licenceId=" +
      licence.id;
    getDocument(url, "Dozvola");
  };

  const previewDocument = async () => {
    let url =
      baseURL +
      "/LicenceWorkflow/GetBasicLicenceDocument?licenceWorkflowId=" +
      licence.id;
    getDocument(url, "Dozvola");
  };

  const createLicenceRenewal = () => {
    let data = licence;
    data.persons = persons;

    navigate("/ExternalExecutorLicences/CreateLicenceRenewal", {
      state: { data },
    });
  };

  const generalView = () => {
    navigate(
      "/ExternalExecutorLicences/GeneralPreview/" + licence.fkLicenceCase
    );
  };

  const renderObjectHierarchy = (objectView) => {
    if (!objectView) {
      return null;
    }

    var returnValue = "";

    if (objectView.parent) {
      if (objectView.parent.parent) {
        returnValue =
          objectView.parent.parent.objectName +
          " -> " +
          objectView.parent.objectName +
          " -> " +
          objectView.objectName;
      } else {
        returnValue =
          objectView.parent.objectName + " -> " + objectView.objectName;
      }
    } else {
      returnValue = objectView.objectName;
    }
    return <>{returnValue}</>;
  };

  const addEmail = () => {
    setEmails([...emails, email]);
    setEmail("");
    setEmailsError(false);
  }

  const removeEmail = (index) => {
    let data = emails;
    data = data.filter((x, i) => i != index);
    setEmails(data);
  }

  return (
    <>
      {history.length > 0 && <History history={history} isLicence={true} />}
      {!StatusForValidation.includes(licence.status) &&
        <>
          {licenceType?.documentType != 9 &&
            <MainContainer>
              <AdditionalLicences
                preview={StatusAccepted.includes(licence.status)}
                canAdd={StatusForAdditionalLicences.includes(licence.status)}
                isD2={true}
                data={d2Licences}
                baseLicence={licence}
              />
            </MainContainer>
          }
          <MainContainer>
            <AdditionalLicences
              preview={StatusAccepted.includes(licence.status)}
              canAdd={StatusForAdditionalLicences.includes(licence.status)}
              isD2={false}
              data={d3Licences}
              baseLicence={licence}
            />
          </MainContainer>
        </>
      }
      <MainContainer>
        <Attachments
          attachments={attachments}
          setAttachments={setAttachments}
          attachmentsPreview={attachmentsPreview}
          setAttachmentsPreview={setAttachmentsPreview}
          canAdd={licence.privilageForTheLoggedUser?.canWorkOnActiveStep == true &&
            StatusForRejection.includes(licence.status)}
          getDocument={getDocument}
          licenceId={licence.id}
        />
      </MainContainer>
      <MainContainer className="clearfix">
        <h4>
          {licence.ledgerNumber}&nbsp;
          {licence.status != 3 && (
            <button
              type="button"
              className="btn btn-incomplete m-2"
              title="Pregled generisane dozvole"
              onClick={previewDocument}
            >
              <FontAwesomeIcon icon={solid("eye")} />
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            title="Generalni prikaz"
            onClick={generalView}
          >
            Generalni prikaz
          </button>
          {StatusAccepted.includes(licence.status) && isLicenceValid && (
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={createLicenceRenewal}
              title="Dopuna dozvole"
            >
              <FontAwesomeIcon icon={solid("copy")} />
            </button>
          )}
        </h4>
        {id && (
          <>
            <h5>
              <span style={{ fontWeight: "400" }}>{t("Status")}: </span>
              <LicenceStatus value={licence.status} />
            </h5>
            <br />
          </>
        )}
        <h5 className="light-header">
          {licenceRenewal &&
            <>
              <label>
                Dopuna dozvole&nbsp;
              </label>
              <a href={"/ExternalExecutorLicences/PreviewLicence/" + licenceRenewalId}>{licenceRenewalName}</a>
              <span> - </span>
            </>
          }
          {licenceType?.name}</h5>
        <label>{t("Foreigner")}:&nbsp;</label>
        <span>{licence.foreigner ? t("Yes") : t("No")}</span>
        <div className="col-md-12">
          <label>{t("Objects")}:&nbsp;</label>
          {licence &&
            licence.licenceObjectsView &&
            licence.licenceObjectsView.map((objectView, index) => (
              <div key={index}>{renderObjectHierarchy(objectView)}</div>
            ))}
        </div>
        <LicenceForm
          model={licence}
          setModel={setLicence}
          mode={"preview"}
          licenceType={licenceType?.documentType}
        />
        <br />
        <SAPContract
          fkSapcontract={licence.fkSapcontract}
          handleChange={{}}
          canChange={false}
          contractData={licence.fkSapcontractNavigation}
        />
        <SearchPersons
          sapContract={licence.fkSapcontract}
          edit={false}
          personsData={persons}
          foreigner={licence.foreigner}
        />
        <SearchEquipments
          sapContract={licence.fkSapcontract}
          edit={false}
          equipmentsData={equipments}
        />
        {StatusForRejection.includes(licence.status) &&
          licence.privilageForTheLoggedUser != null &&
          licence.privilageForTheLoggedUser.canWorkOnActiveStep == true && (
            <>
              <div className="clearfix pull-right">
                <ButtonWithConfirmation
                  className="btn btn-accept m-2"
                  onClick={() => {
                    setRejecting(false);
                    if (StatusForThirdStep.includes(licence.status) && objectLevel == 3) {
                      checkAcceptForThirdStep();
                    } else {
                      accept(false);
                    }
                  }}
                  contentText={"Zahtev će biti odobren."}
                  handleYesText={t("Accept")}
                >
                  {t("Approve")}
                </ButtonWithConfirmation>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setRejecting(true);
                    ShowDialog();
                  }}
                >
                  {t("Reject")}
                </button>
              </div>
            </>
          )}
        {StatusAccepted.includes(licence.status) && (
          <div className="clearfix">
            <button
              type="button"
              className="btn btn-primary pull-right"
              onClick={() => {
                setRejecting(false);
                ShowDialog();
              }}
            >
              Obavesti objekat o izdatoj dozvoli
            </button>
          </div>
        )}
        <DialogModal
          show={show}
          onHide={HideDialog}
          title={
            rejecting
              ? "Odbijanje zahteva"
              : "Slanje obaveštenja objektu o izdatoj dozvoli"
          }
        >
          {!rejecting && (
            <>
              <label>E-mail</label>
              <div className="row col-md-12">
                <div className="col-md-8">
                  <input
                    className="form-control"
                    value={email}
                    onChange={(e) => handlePropertyChange("email", e.target.value)}
                  />
                  {errors?.email && <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="col-md-4">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={addEmail}
                    disabled={email == "" || errors.email}>
                    {t("Add")}
                  </button>
                </div>
              </div>
              <div className="emails-div">
                {emails.map((x, i) => {
                  return (
                    <div key={i}>
                      {x}&nbsp;
                      <FontAwesomeIcon
                        className="text-color"
                        onClick={() => removeEmail(i)}
                        icon={solid("remove")}
                      />
                    </div>
                  )
                })}
              </div>
            </>
          )}
          <div className="form-group">
            <label>{t("Comment")}</label>
            <textarea
              className="form-control"
              value={comment}
              onChange={(e) => handlePropertyChange("comment", e.target.value)}
            ></textarea>
            {errors?.comment && <div className="text-danger">{errors.comment}</div>}
          </div>
          {rejecting ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={checkRejectValidation}
            >
              {t("Reject")}
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendNotification}
              >
                {t("SendNotification")}
              </button>
              {emailsError && <div className="text-danger">{t("AtLeastOneEmail")}</div>}
            </>
          )}
        </DialogModal>
        <DialogModal
          show={showDoc}
          onHide={HideDocument}
          title={StatusForRejection.includes(licence.status) ? t("LicenceInfoWarning") : docName}
          download={true}
          downloadDocument={downloadDocument}
          warning={StatusForRejection.includes(licence.status)}
        >
          <div>
            <iframe src={doc} style={{ width: "100%", height: "800px" }} />
          </div>
        </DialogModal>
        <DialogModal
          show={showComm}
          onHide={HideComment}
          title={<LicenceStatus value={licence.status} />}
        >
          <label>Komentar odbijanja:</label>
          <div>{rejectComment}</div>
        </DialogModal>
        {showAlert && (
          <AlertDialog
            handleYes={() => {
              if (rejecting) {
                reject();
              } else {
                accept(true);
              }
              HideAlertDialog();
            }}
            handleNo={() => {
              if (!rejecting) {
                accept(false);
              }
              HideAlertDialog();
            }}
            show={showAlert}
            contentText={rejecting ? "Zahtev će biti odbijen." : "Da li želite da automatski odobrite i treći korak validacije?"}
            handleYesText={rejecting ? t("Approve") : t("Yes")}
            handleNoText={rejecting ? t("Cancel") : t("No")}
            className={"btn btn-primary"}
          ></AlertDialog>
        )}
      </MainContainer>
    </>
  );
};

export default PreviewLicence;
