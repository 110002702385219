import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputEquipmentModel } from "../../../models/InputModels";
import CreateInputEquipment from "../../../components/bzr-service/create-input-equipment/createInputEquipment.component";
import Client from "../../../api-services/bzr-service/client";
import { MainContainer } from "../../../common/layout/Layout.style";
import { serverValidation } from "../../../common/validation/validation";

const CreateEquipment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, contract } = useParams();

  const [equipment, setEquipment] = useState(new InputEquipmentModel());
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);

  var client = new Client();

  useEffect(() => {
    if (id) {
      getEquipment(id);
    } else {
      setEdit(false);
    }
  }, []);

  const getEquipment = async (id) => {
    let data = await client.GetEquipment(id);
    setEquipment(data);
    setEdit(true);
  };

  const createEquipment = async (equipmentData) => {
    if (edit) {
      var response = await client.UpdateEquipment(equipmentData);
    } else {
      var response = await client.CreateEquipment(equipmentData);
    }
    if (response.code === 2) {
      let result = serverValidation(response.data, t);
      setErrors(result);
    } else if (response && response != "Error") {
      navigate("/BZRService/BZREquipments");
    }
  };

  return (
    <MainContainer>
      <CreateInputEquipment
        withContract={contract == "true"}
        addEquipment={createEquipment}
        equipmentData={equipment}
        isEdit={edit}
        inputEquipment={false}
        HideDialog={undefined}
        errors={errors}
        setErrors={setErrors}
      />
    </MainContainer>
  );
};

export default CreateEquipment;
