import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/admin-panel/client";
import SapClient from "../../../api-services/sap-contract/client";
import LicenceClient from "../../../api-services/licences/client";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { style } from "../../../common/select-style/SelectStyle";

const SearchLicence = ({
  filter,
  setFilter,
  searchData,
  resetFilter,
  type,
}) => {
  const { t } = useTranslation();
  const [licenceTypeOpts, setLicenceTypeOpts] = useState([]);
  const requestOptions = [
    {
      value: 1,
      label: "Drugi korak",
    },
    {
      value: 2,
      label: "Treći korak",
    },
    {
      value: 5,
      label: "Validacija stranih lica",
    },
  ];
  const complitedOptions = [
    {
      value: 3,
      label: "Odbijen",
    },
    {
      value: 4,
      label: "Odobren",
    },
  ];
  const [statusOpts, setStatusOpts] = useState(
    type == 1 ? complitedOptions : requestOptions
  );

  var client = new Client();
  var sapClient = new SapClient();
  var licenceClient = new LicenceClient();

  useEffect(() => {
    if (type != 1) {
      getLicenceTypes();
    }
  }, []);

  const getObjects = async (value) => {
    if (value.length > 2) {
      let data = { filter: { Name: value }, page: 1, pageSize: 2147483647 };
      let response = await client.SearchObjects(data, true);
      return response.results;
    }
  };

  const getCompanies = async (value) => {
    if (value.length > 0) {
      let data = {
        filter: { companyName: value },
        page: 1,
        pageSize: 20,
      };
      let response = await client.SearchCompanies(data, true, true);
      return response.results;
    }
  };

  const getSapContracts = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: { contractNumber: value },
        page: 1,
        pageSize: 2147483647,
        fieldNamesForSort: [
          {
            sortDirection: 0,
            columnName: "ContractNumber"
          }
        ]
      };
      let response = await sapClient.SearchSAPContracts(data, true);
      return response.results;
    }
  };

  const getLicenceTypes = async () => {
    let response = await licenceClient.GetAllLicenceTypes();
    let types = response.map((x) => {
      return { value: x.id, label: x.name };
    });
    setLicenceTypeOpts(types);
  };

  const handleContractChange = (contract, isCompanyContract) => {
    if (isCompanyContract) {
      let contracts = contract ? contract.sapContractsForCompany : [];
      setFilter({
        ...filter,
        sapcontractIds: filter.sapContracts ? [...filter.sapContracts, ...contracts] : contracts,
        companyContract: contract
      });
    } else {
      let contracts = contract ? contract : [];
      setFilter({
        ...filter,
        sapcontractIds: filter.companyContract ? [...filter.companyContract.sapContractsForCompany, ...contracts] : contracts,
        sapContracts: contracts
      });
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("RequestNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.ledgerNumber}
            onChange={(e) =>
              setFilter({
                ...filter,
                ledgerNumber: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("CompanyName")}</label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            styles={style}
            value={filter.companyContract}
            getOptionLabel={(e) => e.companyName}
            getOptionValue={(e) => e.id}
            loadOptions={getCompanies}
            onChange={(value) => {
              handleContractChange(value, true);
            }}
            placeholder="Počnite da kucate da biste proširili pretragu..."
            isClearable
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ExpertPerson")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.firstAndLastNameOfTheExpertPerson}
            onChange={(e) =>
              setFilter({
                ...filter,
                firstAndLastNameOfTheExpertPerson: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Status")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={statusOpts}
            onChange={(e) => {
              let chosenStatuses =
                e.length > 0
                  ? e.map((x) => {
                    return x.value;
                  })
                  : null;
              setFilter({
                ...filter,
                statuses: chosenStatuses,
              });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={statusOpts.filter((x) => filter.statuses?.includes(x.value))}
            isMulti
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ContractNumber")}</label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            styles={style}
            value={filter.sapContracts}
            getOptionLabel={(e) => e.contractNumber + " (" + e.contractingParty + ")"}
            getOptionValue={(e) => e.id}
            loadOptions={getSapContracts}
            onChange={(value) =>
              handleContractChange(value, false)
            }
            placeholder="Potrebno je uneti minimum 3 karaktera..."
            isClearable
            isMulti
          />
        </div>
        {type == 1 ? (
          <>
            <div className="form-group col-md-4">
              <label>Naziv objekta</label>
              <input
                type="text"
                className="form-control"
                value={filter.objectName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    objectName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-4">
              <label>Naziv tipa</label>
              <input
                type="text"
                className="form-control"
                value={filter.licenceTypeName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    licenceTypeName: e.target.value,
                  })
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-group col-md-4">
              <label>Objekti za koje se izdaje dozvola</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                styles={style}
                value={filter.objectIds}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e.id}
                loadOptions={getObjects}
                onChange={(value) =>
                  setFilter({
                    ...filter,
                    objectIds: value.length > 0 ? value : null,
                  })
                }
                placeholder="Potrebno je uneti minimum 3 karaktera..."
                isClearable
                isMulti
              />
            </div>
            <div className="form-group col-md-4">
              <label>Tipovi dozvola</label>
              <Select
                placeholder={t("SelectFromList")}
                options={licenceTypeOpts}
                onChange={(value) => {
                  setFilter({
                    ...filter,
                    licenceTypeIds: value.length > 0 ? value : null,
                  });
                }}
                menuPortalTarget={document.body}
                styles={style}
                value={filter.licenceTypeIds}
                isMulti
              />
            </div>
          </>
        )}
        <div className="form-group col-md-4">
          <label>Datum početka važnosti dozvole</label>
          <br />
          <CustomDatePicker
            selected={filter.licenceValidityDateFrom}
            onChange={(date) =>
              setFilter({
                ...filter,
                licenceValidityDateFrom: date,
              })
            }
            isClearable
          />
        </div>
        <div className="form-group col-md-4">
          <label>Datum prestanka važnosti dozvole</label>
          <br />
          <CustomDatePicker
            selected={filter.licenceValidityDateTo}
            onChange={(date) =>
              setFilter({
                ...filter,
                licenceValidityDateTo: date,
              })
            }
            isClearable
          />
        </div>
      </div>
      <div className="col-md-12 text-right">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            searchData();
          }}
        >
          {t("Search")}
        </button>
        <button
          type="button"
          className="btn btn-cancel"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            resetFilter();
          }}
        >
          {t("CancelSearch")}
        </button>
      </div>
    </form>
  );
};

export default SearchLicence;
