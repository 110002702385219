import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async GetUser() {
    try {
      var response = await api.get(baseURL + "/InternalUser/GetUserProfile");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetReplacementUsers(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/Replacements/SearchAvailableReplacements",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAllReplacements() {
    try {
      var response = await api.get(
        baseURL + "/Replacements/GetAllReplacementsForEmployee"
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetEmployeeReplacements() {
    try {
      var response = await api.get(
        baseURL +
          "/Replacements/GetAllEmployeeOfEmployeesWhoseIAmCurrentReplacement"
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateReplacement(data) {
    try {
      var response = await api.post(
        baseURL + "/Replacements/CreateReplacementForEmployee",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeleteReplacement(replacementId) {
    try {
      var response = await api.post(
        baseURL +
          "/Replacements/DeleteReplacementForEmployee?replacementId=" +
          replacementId
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
