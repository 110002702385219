import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { MainContainer } from "../../../common/layout/Layout.style";
import { EmployeeLicence } from "../../../models/LicenceModel";
import SearchObjects from "../search-objects/searchObjects.component";
import Client from "../../../api-services/employee-licences/client";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import Joi from "joi-browser";
import { validateForm } from "../../../common/validation/validation";
import { style } from "../../../common/select-style/SelectStyle";

const CreateEmployeeLicence = ({ createForYourself }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [licence, setLicence] = useState(new EmployeeLicence());
  const [employeeOpts, setEmployeeOpts] = useState();
  const [attachments, setAttachments] = useState([]);
  const [employeeError, setEmployeeError] = useState(false);
  const [objectError, setObjectError] = useState(false);
  const [errors, setErrors] = useState({});

  const schema = {
    dateFrom: Joi.date().required(),
    dateTo: Joi.date().required(),
  };

  var client = new Client();

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    let response = await client.GetAllSubordinates(true);
    let employees = response.map((x) => {
      return {
        value: x.employeeId,
        label: x.firstAndLastName + " (" + x.email + ")",
      };
    });
    setEmployeeOpts(employees);
  };

  const createLicence = async () => {
    const errorsResult = validateForm(licence, schema, t);
    setErrors(errorsResult);

    let objError = !licence.fK_Object;
    setObjectError(objError);

    let errorEmployee =
      !createForYourself && licence.applicantEmployeeIds.length < 1;
    setEmployeeError(errorEmployee);

    if (!errorsResult && !objError && !errorEmployee) {
      let data = {
        ...licence,
        applicantEmployeeIds: licence.applicantEmployeeIds.map((x) => x.value),
      };
      var formData = new FormData();
      serializeObjectIntoFormData(formData, data);

      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i].guid)
          formData.append(
            "outOfWorkingTimeLicenceWorkflowDocuments[" + i + "].guid",
            attachments[i].guid
          );
        else
          formData.append(
            "outOfWorkingTimeLicenceWorkflowDocuments[" + i + "].file",
            attachments[i]
          );

        formData.append(
          "outOfWorkingTimeLicenceWorkflowDocuments[" + i + "].name",
          attachments[i].name
        );
      }

      if (createForYourself) {
        let response = await client.CreateLicenceForYourself(formData);
        if (response != "Error") {
          navigate("/EmployeeLicences/MyLicences");
        }
      } else {
        let response = await client.CreateLicenceForSubordinates(formData);
        if (response != "Error") {
          navigate("/EmployeeLicences");
        }
      }
    }
  };

  return (
    <MainContainer>
      <h4>
        {createForYourself
          ? "Kreiranje novog zahteva"
          : "Kreiranje nove dozvole"}
      </h4>
      <SearchObjects licence={licence} setLicence={setLicence} />
      {objectError && (
        <div className="text-danger">
          Morate izabrati objekat drugog ili trećeg nivoa.
        </div>
      )}
      <br />
      <div className="row">
        {!createForYourself && (
          <div className="form-group col-md-4">
            <label>Ime i prezime osobe za koju se traži odobrenje</label>
            <Select
              placeholder={t("SelectFromList")}
              options={employeeOpts}
              onChange={(e) => {
                setLicence({ ...licence, applicantEmployeeIds: e });
                if (e.length > 0) {
                  setEmployeeError(false);
                }
              }}
              menuPortalTarget={document.body}
              styles={style}
              value={licence.applicantEmployeeIds}
              isMulti
            />
            {employeeError && (
              <div className="text-danger">
                Morate izabrati bar jednog zaposlenog.
              </div>
            )}
          </div>
        )}
        <div className="form-group col-md-4">
          <label>Opis zahteva</label>
          <textarea
            type="text"
            className="form-control"
            value={licence.description}
            onChange={(e) =>
              setLicence({ ...licence, description: e.target.value })
            }
          ></textarea>
        </div>
        <div className="form-group col-md-4">
          <label>Razlog planiranog dolaska</label>
          <textarea
            type="text"
            className="form-control"
            value={licence.purposeOfComing}
            onChange={(e) =>
              setLicence({ ...licence, purposeOfComing: e.target.value })
            }
          ></textarea>
        </div>
        <div className="form-group col-md-4">
          <label>{t("Comment")}</label>
          <textarea
            type="text"
            className="form-control"
            value={licence.comment}
            onChange={(e) =>
              setLicence({ ...licence, comment: e.target.value })
            }
          ></textarea>
        </div>
        <div className="form-group col-md-4">
          <label>Datum početka važnosti dozvole</label>
          <br />
          <CustomDatePicker
            selected={licence.dateFrom}
            onChange={(date) => setLicence({ ...licence, dateFrom: date })}
          />
          {errors?.dateFrom && (
            <div className="text-danger">{errors.dateFrom}</div>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>Datum prestanka važnosti dozvole</label>
          <br />
          <CustomDatePicker
            selected={licence.dateTo}
            onChange={(date) => setLicence({ ...licence, dateTo: date })}
          />
          {errors?.dateTo && <div className="text-danger">{errors.dateTo}</div>}
        </div>
      </div>
      <div className="col-md-6">
        <label>Prilozi</label>
        <MultipleFiles
          fileList={attachments}
          setFileList={setAttachments}
          accept={".pdf"}
          name={"attachments"}
        />
      </div>
      <div className="clearfix">
        <ButtonWithConfirmation
          className="btn btn-accept pull-right"
          onClick={createLicence}
          contentText={
            createForYourself
              ? "Zahtev će biti kreiran"
              : "Dozvola će biti kreirana"
          }
          handleYesText={t("Accept")}
        >
          {createForYourself ? "Kreiraj zahtev" : "Kreiraj dozvolu"}
        </ButtonWithConfirmation>
      </div>
    </MainContainer>
  );
};

export default CreateEmployeeLicence;
