import { dateWithoutTimezone } from "../custom-date-picker/CustomDatePicker.component";

export const formElements = [
    {
        licenceType: "all",
        elementName: "purposeOfVisitDescription",
        elementType: "input",
        required: "all",
        initialValue: "",
        disabled: false
    },
    {
        licenceType: [1, 2, 3, 5, 7],
        elementName: "numberOfPersons",
        elementType: "input",
        required: [],
        initialValue: "",
        disabled: true
    },
    {
        licenceType: [1, 2, 3, 5, 7, 8],
        elementName: "licenceValidityDateFrom",
        elementType: "date",
        required: [1, 2, 3, 5, 7, 8],
        initialValue: dateWithoutTimezone(new Date()),
        disabled: false
    },
    {
        licenceType: [1, 2, 3, 5, 7, 8],
        elementName: "licenceValidityDateTo",
        elementType: "date",
        required: [1, 2, 3, 5, 7, 8],
        initialValue: dateWithoutTimezone(new Date()),
        disabled: false
    },
    // {
    //     licenceType: [1, 2, 3, 4, 5, 6, 7],
    //     elementName: "companyName",
    //     elementType: "input",
    //     required: [1, 2, 3, 4, 5, 6, 7],
    //     initialValue: "",
    //     disabled: false
    // },
    {
        licenceType: [1, 2, 3, 5, 7],
        elementName: "firstAndLastNameOfTheExpertPerson",
        elementType: "input",
        required: [1, 2, 3, 5, 7],
        initialValue: "",
        disabled: false
    },
    {
        licenceType: [3, 5],
        elementName: "isTheBzrAgreementAnIntegralPartOfTheContract",
        elementType: "checkbox",
        required: [3, 5],
        initialValue: false,
        disabled: false
    },
    {
        licenceType: [1, 3],
        elementName: "vehicleMakeModelAndPlateNumber",
        elementType: "input",
        required: [3],
        initialValue: "",
        disabled: false
    },
    {
        licenceType: [1, 3, 5],
        elementName: "contactPhoneNumberOfTheExpertPerson",
        elementType: "input",
        required: [1, 3, 5],
        initialValue: "",
        disabled: false
    },
    {
        licenceType: [2, 4, 6, 9],
        elementName: "visitDate",
        elementType: "date",
        required: [2, 4, 6, 9],
        initialValue: dateWithoutTimezone(new Date()),
        disabled: false
    },
    {
        licenceType: [6],
        elementName: "requestDescription",
        elementType: "input",
        required: [6],
        initialValue: "",
        disabled: false
    },
    {
        licenceType: [4, 6, 8, 9],
        elementName: "firstAndLastNameOfThePersonWhoAcceptsVisit",
        elementType: "input",
        required: [4, 6, 8, 9],
        initialValue: "",
        disabled: false
    },
    // {
    //     licenceType: [4, 6, 8, 9],
    //     elementName: "firstAndLastNameOfThePersonWhoAnnouncingTheVisit",
    //     elementType: "input",
    //     required: [4, 6, 8, 9],
    //     initialValue: "",
    //     disabled: false
    // },
]