import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { srLatn } from "date-fns/locale";

function convertLocalToUTCDate(date) {
  if (date) return new Date(moment.utc(date.toDateString()).format());
  else return null;
}

//akcija koja formatira datum kada ga prikupi sa beck-a
export const formatDateForUpdate = (date) => {
  if (date) return new Date(new Date(moment.utc((new Date(date)).toDateString()).format()));
  else return null;
}

export const dateWithoutTimezone = (date) => {
  if (date) {
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return withoutTimezone;
  }
};

export default function CustomDatePicker({ selected, onChange, ...props }) {
  return (
    <DatePicker
      selected={selected ? new Date(selected) : null}
      locale={srLatn}
      dateFormat="dd.MM.yyyy"
      className="form-control"
      showMonthDropdown
      useShortMonthInDropdown
      strictParsing
      onChange={(date) => onChange(dateWithoutTimezone(date))}
      {...props}
    />
  );
}
