import React from 'react';

const StatusEnum = {
  1: "Aktivna", // Active
  2: "Stornirana", // Cancellation
  3: "Odbijena", // Reject
};

const AdditionalLicenceStatus = ({ value }) => {
  return <span title={StatusEnum[value]}>{StatusEnum[value]}</span>;
};

export default AdditionalLicenceStatus;