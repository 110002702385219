import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExternalUserModel } from "../../../models/UserModel";
import Client from "../../../api-services/admin-panel/client";
import { MainContainer } from "../../../common/layout/Layout.style";
import Company from "../../../components/admin-panel/company/company.component";
import DialogModal from "../../../common/modal/Modal.component";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { Info } from "../../../common/toastify/toastify";

const CreateUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const userRef = useRef(null);
  const [user, setUser] = useState(new ExternalUserModel());
  const [newPassword, setNewPassword] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);

  const schema = {
    companyIdentifier: Joi.string().required(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string().email().required(),
    password: Joi.optional(),
  };

  const passwordSchema = {
    newPassword: Joi.string().regex(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/).optional()
  }

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);
  const ShowConfirmDialog = () => setShowConfirm(true);
  const HideConfirmDialog = () => setShowConfirm(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, []);

  const getUser = async (id) => {
    let data = await client.GetUser(id);
    setUser(data);
    setEdit(true);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, user, errors, schema, t);
    setUser(result.data);
    setErrors(result.errors);
  };

  const handlePasswordChange = (value) => {
    const obj = { name: "newPassword", value: value };
    const result = handleSave(obj, null, errors, passwordSchema, t);
    setNewPassword(value);
    setErrors(result.errors);
  };

  const createUser = async () => {
    const errorsResult = validateForm(user, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      if (edit) {
        var response = await client.UpdateUser(user);
      } else {
        var response = await client.CreateUser(user);
      }
      if (response.code === 2) {
        let result = serverValidation(response.data, t);
        setErrors(result);
      } else if (response && response != "Error") {
        setUser(response);
        if (edit) {
          navigate("/AdminPanel/ExternalUsers");
        } else {
          ShowConfirmDialog();
        }
      }
    }
  };

  const checkValidation = () => {
    const errorsResult = validateForm({ newPassword }, passwordSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      ShowAlertDialog();
    }
  }

  const resetPassword = async () => {
    let data = { id: user.id, password: newPassword };
    let response = await client.ResetPassword(data);
    if (response != "Error") {
      setNewPassword("");
      HideDialog();
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(userRef.current.textContent);
    Info(t("CopySuccess"));
  }

  const closeDialog = () => {
    HideConfirmDialog();
    navigate("/AdminPanel/ExternalUsers");
  }

  return (
    <>
      <MainContainer>
        {edit ? (
          <>
            <h4>Izmena eksternog korisnika</h4>
            <div className="form-group col-md-4">
              <label>{t("Active")}</label>
              &nbsp;
              <input
                type="checkbox"
                value={user.enabled}
                checked={user.enabled}
                onChange={() => setUser({ ...user, enabled: !user.enabled })}
              />
            </div>
          </>
        ) : (
          <h4>Kreiranje eksternog korisnika</h4>
        )}
        <Company user={user} handleChange={handlePropertyChange} edit={edit} />
        {errors?.companyIdentifier && (
          <div className="text-danger">{errors.companyIdentifier}</div>
        )}
        <div className="form-group col-md-4">
          <label>{t("Name")}</label>
          <input
            type="text"
            className="form-control"
            value={user.firstName}
            onChange={(e) => handlePropertyChange("firstName", e.target.value)}
          />
          {errors?.firstName && (
            <div className="text-danger">{errors.firstName}</div>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>{t("LastName")}</label>
          <input
            type="text"
            className="form-control"
            value={user.lastName}
            onChange={(e) => handlePropertyChange("lastName", e.target.value)}
          />
          {errors?.lastName && (
            <div className="text-danger">{errors.lastName}</div>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={user.email}
            onChange={(e) => handlePropertyChange("email", e.target.value)}
          />
          {errors?.email && <div className="text-danger">{errors.email}</div>}
        </div>
        {!edit && (
          <div className="form-group col-md-4">
            <label>Lozinka će biti automatski kreirana.</label>
            {/* <input
              type="password"
              className="form-control"
              value={user.password}
              onChange={(e) => handlePropertyChange("password", e.target.value)}
            />
            {errors?.password && (
              <div className="text-danger">{errors.password}</div>
            )} */}
          </div>
        )}
        <button type="button" className="btn btn-accept" onClick={createUser}>
          {edit ? t("SaveChanges") : t("Create")}
        </button>
        {edit &&
          <div style={{ marginTop: "50px" }}>
            <button type="button" className="btn btn-incomplete" onClick={ShowDialog}>
              {t("ChangePassword")}
            </button>
          </div>
        }
        <DialogModal
          show={showConfirm}
          onHide={HideConfirmDialog}
          title={t("UserCreateSuccess")}
          noClose={true}
          backdrop="static"
        >
          <div ref={userRef} className="form-group">
            Kreiran je nalog za korisnika <i>{user.firstAndLastName}</i>. Kredencijali za pristup su:
            <br />&nbsp;
            <b>E-mail:</b> {user.email}
            <br />&nbsp;
            <b>Lozinka:</b> {user.unencryptedPassword}
          </div>
          <div>
            <button type="button" className="btn btn-primary" onClick={copyText}>
              {t("Copy")}
            </button>
            <button type="button" className="btn btn-cancel pull-right" onClick={closeDialog}>
              {t("Close")}
            </button>
          </div>
        </DialogModal>
        <DialogModal
          show={show}
          onHide={HideDialog}
          title={"Izmena lozinke"}
        >
          <div className="form-group">
            <label>Nova lozinka</label>
            <input
              type="password"
              className="form-control"
              value={newPassword}
              onChange={(e) => handlePasswordChange(e.target.value)}
            />
            {errors?.newPassword && (
              <div className="text-danger">{errors.newPassword}</div>
            )}
          </div>
          <button
            type="button"
            className="btn btn-incomplete pull-right"
            onClick={checkValidation}
          >
            {t("Change")}
          </button>
        </DialogModal>
        {showAlert && (
          <AlertDialog
            handleYes={() => {
              HideAlertDialog();
              resetPassword();
            }}
            handleNo={() => {
              HideAlertDialog();
            }}
            show={showAlert}
            contentText={"Lozinka će biti izmenjena."}
            handleYesText={t("Accept")}
            className={"btn btn-accept"}
          ></AlertDialog>
        )}
      </MainContainer>
    </>
  );
};

export default CreateUser;
