import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { EquipmentSearchModel } from "../../../models/InputModels";
import Client from "../../../api-services/bzr-service/client";
import SearchEquipment from "../search-equipment/searchEquipment.component";
import { format } from "date-fns";
import PersonEquipmentStatus from "../../../common/person-equipment-status/PersonEquipmentStatus";

const BZREquipments = ({ withContract }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "Equipments";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const equipmentModel = new EquipmentSearchModel();
  equipmentModel.withSapContract = withContract;
  const [filterData, setFilterData] = useState(equipmentModel);
  const filterName = withContract ? "SAP_EQUIPMENT_FILTER" : "EQUIPMENT_FILTER";

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("ContractNumber"),
      accessor: "fkSapcontractNavigation.contractNumber",
    },
    {
      Header: t("Executor"),
      accessor: "companyName",
    },
    {
      Header: t("EquipmentName"),
      accessor: "name",
    },
    {
      Header: t("Model"),
      accessor: "model",
    },
    {
      Header: t("Type"),
      accessor: "type",
    },
    {
      Header: t("IdentificationNumber"),
      accessor: "identificationNumber",
    },
    {
      Header: "Datum unosa",
      accessor: "createdDateTimeOnUtc",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.createdDateTimeOnUtc
              ? format(
                  new Date(row.original.createdDateTimeOnUtc),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ value }) => <PersonEquipmentStatus value={value} />,
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangeEquipment")}
              onClick={() => {
                editEquipment(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem(filterName);
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    var response = await client.SearchEquipments({
      filter: storedObject ? parsedObject : filterData,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem(filterName, JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem(filterName);
    let data = new EquipmentSearchModel();
    data.withSapContract = withContract;
    setFilterData(data);
    var response = await client.SearchEquipments({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const editEquipment = async (id) => {
    navigate("/BZRService/CreateEquipment/" + withContract + "/" + id);
  };

  const createEquipment = () => {
    navigate("/BZRService/CreateEquipment/" + withContract);
  };

  const deleteEquipment = async (id) => {
    let response = await client.DeleteEquipment(id);
    if (response != "Error") {
      searchData();
    }
  };

  return (
    <>
      <SearchEquipment
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
        withContract={withContract}
      />
      <div className="col-md-12">
        <button
          type="button"
          className="btn btn-primary"
          onClick={createEquipment}
        >
          {t("AddInputEquipment")}
        </button>
      </div>
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        hiddenColumn={[
          "id",
          !withContract && "fkSapcontractNavigation.contractNumber",
        ]}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
    </>
  );
};

export default BZREquipments;
