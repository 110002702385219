import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmployeeLicence } from "../../../models/LicenceModel";
import { format } from "date-fns";
import { MainContainer } from "../../../common/layout/Layout.style";
import {
  StatusForPrint,
  StatusForAccepting,
} from "../../../common/employee-licence-request-status/EmployeeLicenceRequestStatus";
import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import Client from "../../../api-services/employee-licences/client";
import DialogModal from "../../../common/modal/Modal.component";
import api from "../../../api";
import { env } from "../../../env";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import LicenceStatus from "../../../common/employee-licence-request-status/EmployeeLicenceRequestStatus";
import UserService from "../../../utils/authentification/keycloak";

var baseURL = env.REACT_APP_API_URL_AAMS;

const PreviewEmployeeLicence = ({ data, forYourself }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [licence, setLicence] = useState(new EmployeeLicence());
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);
  const [show, setShow] = useState(false);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [myRequest, setMyRequest] = useState(false);
  const docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  var client = new Client();

  useEffect(() => {
    setLicence(data);
    let employeeId = UserService.getEmployeeId();
    if (data.applicantEmployeeId == employeeId) {
      setMyRequest(true);
    } else {
      setMyRequest(false);
    }
    let attachments = data.outOfWorkingTimeLicenceWorkflowDocuments.map((x) => {
      return {
        ...x,
        documentType: 1,
      };
    });
    setAttachmentsPreview(attachments);
  }, [data]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const printLicence = async () => {
    let response = await api.get(
      baseURL +
        "/OutOfWorkingTimeLicenceWorkflow/GenerateLicenceDocument?licenceId=" +
        licence.id,
      {
        responseType: "blob",
      }
    );
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    if (docTypes.includes(response.data.type)) {
      downloadDocument(url, "Dozvola");
    } else {
      setDoc(url);
      setDocName("Dozvola");
      ShowDialog();
    }
  };

  const acceptLicence = async () => {
    let response = await client.AcceptLicence(licence.id);
    if (response != "Error") {
      navigate("/EmployeeLicences");
    }
  };

  const rejectLicence = async () => {
    let response = await client.RejectLicence(licence.id);
    if (response != "Error") {
      navigate("/EmployeeLicences");
    }
  };

  const getDocument = async (id, name) => {
    let response = await api.get(
      baseURL + "/OutOfWorkingTimeLicenceWorkflow/GetDocument?id=" + id,
      {
        responseType: "blob",
      }
    );
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    if (docTypes.includes(response.data.type)) {
      downloadDocument(url, name);
    } else {
      setDoc(url);
      setDocName(name);
      ShowDialog();
    }
  };

  const downloadDocument = (url, name) => {
    const link = document.createElement("a");
    link.href = url ? url : doc;
    link.download = name ? name : docName;
    link.click();
  };

  return (
    <>
      {attachmentsPreview.length > 0 && (
        <MainContainer>
          <div>
            <h5>Dokumentacija</h5>
            <MultipleFiles
              fileList={attachmentsPreview}
              setFileList={setAttachmentsPreview}
              accept={".pdf"}
              name={"attachmentsPreview"}
              edit={false}
              getDocument={getDocument}
            />
          </div>
        </MainContainer>
      )}
      <MainContainer>
        <h4>{t("PreviewLicence")}</h4>
        <h5>
          <span style={{ fontWeight: "400" }}>{t("Status")}: </span>
          <LicenceStatus value={licence.status} />
        </h5>
        <div className="row">
          <div className="form-group col-md-4">
            <label>Objekat:</label>
            <div>
              {licence.level2ObjectName
                ? licence.level2ObjectName +
                  (licence.level3ObjectName
                    ? " -> " + licence.level3ObjectName
                    : "")
                : licence.objectName}
            </div>
          </div>
          <div className="form-group col-md-4">
            <label>Organizaciona jedinica:</label>
            <div>{licence.organizationalUnit}</div>
          </div>
          {!forYourself && (
            <div className="form-group col-md-4">
              <label>Ime i prezime osobe za koju se traži odobrenje:</label>
              <div>{licence.firstAndLastNameOfAplicant}</div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label>Opis zahteva:</label>
            <div>{licence.description}</div>
          </div>
          <div className="form-group col-md-4">
            <label>Razlog planiranog dolaska:</label>
            <div>{licence.purposeOfComing}</div>
          </div>
          <div className="form-group col-md-4">
            <label>Komentar:</label>
            <div>{licence.comment}</div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label>Datum početka važnosti dozvole:</label>
            <div>{format(new Date(licence.dateFrom), "dd.MM.yyyy")}</div>
          </div>
          <div className="form-group col-md-4">
            <label>Datum prestanka važnosti dozvole:</label>
            <div>{format(new Date(licence.dateTo), "dd.MM.yyyy")}</div>
          </div>
        </div>
        <div className="clearfix">
          {StatusForPrint.includes(licence.status) && (
            <button
              type="button"
              className="btn btn-primary pull-right"
              onClick={printLicence}
            >
              Štampaj dozvolu
            </button>
          )}
          {!forYourself &&
            !myRequest &&
            StatusForAccepting.includes(licence.status) && (
              <div className="pull-right">
                <ButtonWithConfirmation
                  className="btn btn-accept m-2"
                  onClick={acceptLicence}
                  contentText={"Zahtev će biti odobren."}
                  handleYesText={t("Accept")}
                >
                  {t("Approve")}
                </ButtonWithConfirmation>
                <ButtonWithConfirmation
                  className="btn btn-primary"
                  onClick={rejectLicence}
                  contentText={"Zahtev će biti odbijen."}
                  handleYesText={t("Accept")}
                >
                  {t("Reject")}
                </ButtonWithConfirmation>
              </div>
            )}
        </div>
        <DialogModal
          show={show}
          onHide={HideDialog}
          title={docName}
          download={true}
          downloadDocument={downloadDocument}
        >
          {/* <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} /> */}
          <div>
            <iframe src={doc} style={{ width: "100%", height: "800px" }} />
          </div>
        </DialogModal>
      </MainContainer>
    </>
  );
};

export default PreviewEmployeeLicence;
