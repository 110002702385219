import React, { useState } from 'react'
import Select from "react-select";
import { useTranslation } from "react-i18next";

//valForDifferentMessage-vrednost koja se ispituje da bi se proverilo koja poruka tacno treba da se prikaze

const CustomSelectList = ({ ...props }) => {

  const [isOverflow, setIsOverflow] = useState(false);
  const { t } = useTranslation();

  return (
    <Select
      noOptionsMessage={() =>
        'valForDifferentMessage' in props
          ? props?.valForDifferentMessage
            ? t("noResult")
            : props?.message
          : t("noResult")
      }
      placeholder={t("SelectFromList")}
      menuPosition="fixed"
      onMenuOpen={() => {
        // setTimeout(()=>{
        //   const selectedEl = document.getElementsByClassName(`${props.classNamePrefix}__control--is-focused`)[0];
        //   if(selectedEl){
        //     selectedEl.scrollIntoView({behavior:'smooth'});
        //   }
        // },15);
        setIsOverflow(true)
      }}
      onMenuClose={() => setIsOverflow(false)}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: "#fad1d2",
          primary25: "#fad1d2",
          primary: "#e81f27",
        },
      })}
      
      {...props}
    />
  );
}

export default CustomSelectList