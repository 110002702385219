import React from 'react';

const StatusEnum = {
  1: "Započet proces", // StartWorkflow
  2: "Završen korak", // FinishStep
  3: "Izmena", // Update 
  4: "Dodavanje SAP ugovora", // AddSapContract 
  5: "Dodavanje dokumenta", // AddDocument
  6: "Završen proces", // FinishWorkflow
  7: "Kreirana D2 dozvola", // CreateD2
  8: "Izmenjena D2 dozvola", // UpdateD2
  9: "Obrisana D2 dozvola", // DeleteD2
  10: "Kreirana D3 dozvola", // CreateD3
  11: "Izmenjena D3 dozvola", // UpdateD3
  12: "Obrisana D3 dozvola", // DeleteD3
  13: "Stornirana D2 dozvola", // CancelD2
  14: "Stornirana D3 dozvola", // CancelD3
};

const HistoryStatus = ({ value }) => {
  return <span>{StatusEnum[value]}</span>;
};

export default HistoryStatus;