import { useTranslation } from "react-i18next";
import Client from "../../../api-services/admin-panel/client";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import { style } from "../../../common/select-style/SelectStyle";

const SearchSapContract = ({ filter, setFilter, searchData, resetFilter }) => {
  const { t } = useTranslation();
  const [authorizedPeople, setAuthorizedPeople] = useState([]);

  const options = [
    {
      label: t("All"),
      value: undefined
    },
    {
      label: t("Yes"),
      value: true
    },
    {
      label: t("No"),
      value: false
    }
  ];

  var client = new Client();

  useEffect(() => {
    if (
      filter.authorizedPerson &&
      filter.authorizedPerson != authorizedPeople
    ) {
      setAuthorizedPeople(filter.authorizedPerson);
    }
  }, [filter]);

  const handleChange = (value) => {
    setAuthorizedPeople(value);
    setFilter({
      ...filter,
      authorizedPersonEmployeeId: value?.employeeId,
      authorizedPerson: value,
    });
  };

  const getAuthorizedPeople = async (value) => {
    if (value.length > 2) {
      let data = { filter: { usernameOrEmail: value }, page: 1, pageSize: 2147483647 };
      let response = await client.SearchInternalUsers(data, true);
      return response.results;
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("ContractNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.contractNumber}
            onChange={(e) =>
              setFilter({ ...filter, contractNumber: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ContractingParty")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.contractingParty}
            onChange={(e) =>
              setFilter({ ...filter, contractingParty: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("AuthorizedPerson")}</label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            styles={style}
            value={authorizedPeople}
            getOptionLabel={(e) => e.userName + " (" + e.email + ")"}
            getOptionValue={(e) => e.id}
            loadOptions={getAuthorizedPeople}
            onChange={handleChange}
            placeholder="Potrebno je uneti minimum 3 karaktera..."
            isClearable
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("ValidContracts")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={options}
            onChange={(e) => {
              setFilter({ ...filter, isActive: e.value });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={options.filter((x) => x.value === filter.isActive)}
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              searchData();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              resetFilter();
              setAuthorizedPeople([]);
            }}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchSapContract;
