import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Table from "../../../../common/table/Table.component";
import { DateRangeColumnFilter, dateFromFilterFn, dateToFilterFn, SelectColumnFilter } from "../../../../common/table/filterHelpFunction.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DialogModal from "../../../../common/modal/Modal.component";
import AdditionalLicenceStatus from "../../../../common/additional-licence-status/AdditionalLicenceStatus";
import api from "../../../../api";
import { env } from "../../../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;

const AdditionalLicences = ({ data }) => {
    const { t } = useTranslation();
    const [licences, setLicences] = useState([]);
    const filter = {
        filterValue: "equals",
        selectOptions: [
            { value: undefined, label: "Svi" },
            { value: "1", label: "Aktivna" },
            { value: "2", label: "Stornirana" },
            { value: "3", label: "Odbijena" },
        ],
    };

    const [doc, setDoc] = useState(null);
    const [docName, setDocName] = useState("");
    const [showDoc, setShowDoc] = useState(false);

    const ShowDocument = () => setShowDoc(true);
    const HideDocument = () => setShowDoc(false);

    const columns = [
        {
            Header: t("RequestNumber"),
            accessor: "ledgerNumber",
            disableFilters: false,
            Cell: ({ row }) => {
                return (
                    <a href={"/ExternalExecutorLicences/PreviewLicence/" + row.original.fkLicenceWorkflow}>{row.original.ledgerNumber}</a>
                )
            }
        },
        {
            Header: t("DateFrom"),
            accessor: "dateFrom",
            disableFilters: false,
            Filter: DateRangeColumnFilter,
            filter: dateFromFilterFn,
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.dateFrom
                            ? format(new Date(row.original.dateFrom), "dd.MM.yyyy")
                            : ""}
                    </div>
                );
            }
        },
        {
            Header: t("DateTo"),
            accessor: "dateTo",
            disableFilters: false,
            Filter: DateRangeColumnFilter,
            filter: dateToFilterFn,
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.dateTo
                            ? format(new Date(row.original.dateTo), "dd.MM.yyyy")
                            : ""}
                    </div>
                );
            }
        },
        {
            Header: t("Objects"),
            accessor: "objects",
            disableFilters: false,
        },
        {
            Header: t("Persons"),
            accessor: "people",
            disableFilters: false,
        },
        {
            Header: "Stručno lice",
            accessor: "expertPerson",
            disableFilters: false,
        },
        {
            Header: "Odgovorno lice",
            accessor: "responsiblePerson",
            disableFilters: false,
        },
        {
            Header: "Dozvolu izdao",
            accessor: "personWhoIssuedTheLicence",
            disableFilters: false,
        },
        {
            Header: t("Status"),
            accessor: "status",
            disableFilters: false,
            Filter: SelectColumnFilter,
            filter: filter,
            Cell: ({ row }) => {
                return <AdditionalLicenceStatus value={row.original.status} />;
            },
        },
        {
            accessor: "download",
            className: "one-button-column",
            Cell: ({ row }) => {
                return (
                    row.original.status != 3 &&
                    <button
                        type="button"
                        className="btn btn-primary table-btn"
                        onClick={() => {
                            getLicenceDocument(row.original.id, row.original.ledgerNumber);
                        }}
                    >
                        <FontAwesomeIcon icon={solid("download")} />
                    </button>
                );
            },
        },
    ];

    useEffect(() => {
        if (data) {
            setLicences(data);
        }
    }, [data]);

    const getLicenceDocument = async (id, name) => {
        let urlName = baseURL + "/LicenceWorkflow/GetAdditionalLicenceDocument?additionalLicenceId=" + id;
        let response = await api.get(urlName, {
            responseType: "blob",
        });
        var blob = new Blob([response.data], { type: response.data.type });
        var url = window.URL.createObjectURL(blob);
        setDoc(url);
        setDocName(name);
        ShowDocument();
    };

    const downloadDocument = (url, name) => {
        const link = document.createElement("a");
        link.href = url ? url : doc;
        link.download = name ? name : docName;
        link.click();
    };

    return (
        <>
            <Table
                columns={columns}
                data={licences}
                tableName={"AdditionalLicences"}
                rememberFilter={false}
            />
            <DialogModal
                show={showDoc}
                onHide={HideDocument}
                title={docName}
                download={true}
                downloadDocument={downloadDocument}
            >
                <div>
                    <iframe src={doc} style={{ width: "100%", height: "800px" }} />
                </div>
            </DialogModal>
        </>
    )
}

export default AdditionalLicences;