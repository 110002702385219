import { useTranslation } from "react-i18next";
import Select from "react-select";
import { style } from "../../../common/select-style/SelectStyle";

const SearchLicence = ({ filter, setFilter, searchData, resetFilter }) => {
  const { t } = useTranslation();
  const statusOpts = [
    {
      value: 1,
      label: "Podnet zahtev",
    },
    {
      value: 2,
      label: "Prihvaćeno",
    },
    {
      value: 3,
      label: "Odbijeno",
    },
  ];

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>Ime i prezime osobe za koju se traži odobrenje</label>
          <input
            type="text"
            className="form-control"
            value={filter.firstOrLastNameOfApplicant}
            onChange={(e) =>
              setFilter({
                ...filter,
                firstOrLastNameOfApplicant: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>Organizaciona jedinica</label>
          <input
            type="text"
            className="form-control"
            value={filter.organizationalUnit}
            onChange={(e) =>
              setFilter({ ...filter, organizationalUnit: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Status")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={statusOpts}
            onChange={(e) => {
              setFilter({ ...filter, status: e?.value });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={statusOpts.filter((x) => x.value == filter.status)}
            isClearable
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              searchData();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={resetFilter}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchLicence;
