import React from 'react';

const StatusEnum = {
  1: "Podnet zahtev", // Applied
  2: "Prihvaćeno", // Accept
  3: "Odbijeno", // Reject
};

export const StatusForAccepting = [1];
export const StatusForPrint = [2];

const LicenceStatus = ({ value }) => {
  return <span>{StatusEnum[value]}</span>;
};

export default LicenceStatus;