import { useState } from "react";
import AsyncSelect from "react-select/async";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import { UserReplacementModel } from "../../../models/UserModel";
import Client from "../../../api-services/profile/client";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { style } from "../../../common/select-style/SelectStyle";

const CreateReplacement = ({ hide, onCreate }) => {
  const [replacement, setReplacement] = useState(new UserReplacementModel());
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const schema = {
    replacementEmployeeId: Joi.string().required(),
    dateFrom: Joi.optional(),
    dateTo: Joi.optional(),
  };

  var client = new Client();

  const handleChange = (value) => {
    setReplacement({ ...replacement, replacementEmployeeId: value });
  };

  const getReplacementUsers = async (value) => {
    if (value.length > 2) {
      let data = { filter: { usernameOrEmail: value } };
      let response = await client.GetReplacementUsers(data, true);
      return response.results;
    }
  };

  const createReplacement = async () => {
    if (!replacement.permanent) {
      schema.dateFrom = Joi.date().required();
      schema.dateTo = Joi.date().required();
    }

    let data = {
      ...replacement,
      replacementEmployeeId: replacement.replacementEmployeeId.employeeId,
    };

    const errorsResult = validateForm(data, schema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      let response = await client.CreateReplacement(data);
      if (response == "Error") {
        if (response.code === 2) {
          let result = serverValidation(response.data, t);
          setErrors(result);
        }
      } else {
        hide();
        let data = {
          ...response,
          replacement: {
            firstName: replacement.replacementEmployeeId.firstName,
            lastName: replacement.replacementEmployeeId.lastName,
            email: replacement.replacementEmployeeId.email,
          },
        };
        onCreate(data);
      }
    }
  };

  return (
    <>
      <div className="form-group">
        <label>Zaposleni</label>
        <AsyncSelect
          cacheOptions
          defaultOptions
          styles={style}
          name="replacementEmployeeId"
          value={replacement.replacementEmployeeId}
          getOptionLabel={(e) => e.userName + " (" + e.email + ")"}
          getOptionValue={(e) => e.id}
          loadOptions={getReplacementUsers}
          onChange={handleChange}
          placeholder="Potrebno je uneti minimum 3 karaktera..."
          isClearable
        />
        {errors?.replacementEmployeeId && (
          <div className="text-danger">{errors.replacementEmployeeId}</div>
        )}
      </div>
      <div className="form-group">
        <label>Trajna zamena &nbsp;</label>
        <input
          type="checkbox"
          value={replacement.permanent}
          checked={replacement.permanent}
          onChange={() => {
            setReplacement({
              ...replacement,
              permanent: !replacement.permanent,
              dateFrom: null,
              dateTo: null,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label>Datum početka važenja zamene</label>
        <br />
        <CustomDatePicker
          name="dateFrom"
          disabled={replacement.permanent}
          selected={replacement.dateFrom}
          onChange={(date) =>
            setReplacement({ ...replacement, dateFrom: date })
          }
        />
        {errors?.dateFrom && (
          <div className="text-danger">{errors.dateFrom}</div>
        )}
      </div>
      <div className="form-group">
        <label>Datum prestanka važenja zamene</label>
        <br />
        <CustomDatePicker
          name="dateTo"
          disabled={replacement.permanent}
          selected={replacement.dateTo}
          onChange={(date) => setReplacement({ ...replacement, dateTo: date })}
        />
        {errors?.dateTo && <div className="text-danger">{errors.dateTo}</div>}
      </div>
      <button
        type="button"
        className="btn btn-accept"
        onClick={createReplacement}
      >
        {t("Add")}
      </button>
    </>
  );
};

export default CreateReplacement;
