import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Client from "../../../api-services/licences/client";
import { format } from "date-fns";
import SearchLicence from "../../../components/external-executor-licences/search-licence/searchLicence.component";
import { MainContainer } from "../../../common/layout/Layout.style";
import LicenceStatus from "../../../common/licence-request-status/LicenceRequestStatus";
import { SearchLicenceModel } from "../../../models/LicenceModel";

const EELicences = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "EELicences";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(new SearchLicenceModel());

  const columns = [
    {
      Header: t("RequestNumber"),
      accessor: "ledgerNumber",
    },
    {
      Header: t("ContractNumber"),
      accessor: "fkSapcontractNavigation.contractNumber",
    },
    {
      Header: t("ExpertPerson"),
      accessor: "firstAndLastNameOfTheExpertPerson",
    },
    {
      Header: "Datum početka važnosti dozvole",
      accessor: "licenceValidityDateFrom",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.licenceValidityDateFrom
              ? format(
                  new Date(row.original.licenceValidityDateFrom),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Datum prestanka važnosti dozvole",
      accessor: "licenceValidityDateTo",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.licenceValidityDateTo
              ? format(
                  new Date(row.original.licenceValidityDateTo),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ row }) => {
        return <LicenceStatus value={row.original.status} />;
      },
    },
    {
      Header: t("LicenceRenewal"),
      accessor: "initialLicenceInTheCase",
      Cell: ({ value }) => {
        return value ? t("No") : t("Yes");
      },
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary"
              title={t("PreviewLicence")}
              onClick={() => previewRequest(row.original.id)}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("LICENCES_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    let filter = storedObject ? parsedObject : filterData;

    var response = await client.SearchLicences({
      filter: {
        ...filter,
        sapcontractIds: filter.sapcontractIds?.map((x) => x.id),
      },
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem("LICENCES_FILTER", JSON.stringify(filterData));
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("LICENCES_FILTER");
    let data = new SearchLicenceModel();
    setFilterData(data);
    var response = await client.SearchLicences({
      filter: data,
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const previewRequest = async (id) => {
    navigate("/ExternalExecutorLicences/PreviewLicence/" + id);
  };

  return (
    <MainContainer>
      <SearchLicence
        filter={filterData}
        setFilter={setFilterData}
        searchData={searchData}
        resetFilter={resetFilters}
        type={filterData.licenceWorkflowFilterType}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        resetFilters={reset}
      />
    </MainContainer>
  );
};

export default EELicences;
