import { useNavigate } from "react-router-dom";
import useAppUrl from "../../hooks/useAppUrl";
import useUserRoles from "../../hooks/useUserRoles";
import { useEffect } from "react";
import { listOfApps } from "./listOfApps";
import { MainPageContainer } from "./Index.style";
import RenderOnRole from "../../common/route-and-role/RenderOnRole";
import { navigateToPage } from "../../common/route-and-role/pathBasedOnRole";

const Index = () => {
  const navigate = useNavigate();
  const { setAppPath, setAppName } = useAppUrl();
  const { userRoles, setUserRoles } = useUserRoles();

  useEffect(() => {
    setAppPath("/");
    setAppName("");
  }, []);

  const onClickNavigate = (path, name) => {
    setAppName(name);
    setAppPath(path);
    navigateToPage(path, navigate, userRoles);
    //navigate(path);
  };

  return (
    <div className="container">
      <div className="row justify-center">
        {listOfApps.map((item, index) => {
          return (
            <RenderOnRole key={index} roles={item.roles || []}>
              <MainPageContainer
                onClick={() => {
                  onClickNavigate(item.path, item.name);
                }}
              >
                <div>
                  <div className="app-box-container">
                    <div className="n-s-title">
                      <span>{item.icon}</span>
                      {item.name}
                    </div>
                  </div>
                </div>
              </MainPageContainer>
            </RenderOnRole>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
