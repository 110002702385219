import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonEquipmentStatus from "../../../../common/person-equipment-status/PersonEquipmentStatus";
import { SelectColumnFilter } from "../../../../common/table/filterHelpFunction.component";
import Table from "../../../../common/table/Table.component";

const Equipments = ({ data }) => {
  const { t } = useTranslation();
  const [equipments, setEquipments] = useState([]);
  const filter = {
    filterValue: "equals",
    selectOptions: [
      { value: undefined, label: "Svi" },
      { value: "1", label: "Aktivan" },
      { value: "2", label: "Neaktivan" },
      { value: "4", label: "Suspendovan" },
    ],
  };

  const columns = [
    {
      Header: t("Status"),
      accessor: "bzrStatus",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ value }) => {
        return <PersonEquipmentStatus value={value} />;
      },
    },
    {
      Header: t("Name"),
      accessor: "name",
      disableFilters: false,
    },
    {
      Header: t("Model"),
      accessor: "model",
      disableFilters: false,
    },
    {
      Header: t("Type"),
      accessor: "type",
      disableFilters: false,
    },
    {
      Header: t("IdentificationNumber"),
      accessor: "identificationNumber",
      disableFilters: false,
    },
  ];

  useEffect(() => {
    if (data) {
      setEquipments(data);
    }
  }, [data]);

  return (
    <Table
      columns={columns}
      data={equipments}
      tableName={"Equipments"}
      rememberFilter={false}
    />
  );
};

export default Equipments;
