import React from "react";

const TypeEnum = {
  1: "Prilog",
  2: "Osnovna dozvola",
};

const DocumentType = ({ value }) => {
  return <span title={TypeEnum[value]}>{TypeEnum[value]}</span>;
};

export default DocumentType;
