import PublicRoute from "./publicRoute";
import UserService from "../../utils/authentification/keycloak";
import { getRolesForLocation } from "./getRolesForLocation";
import { Navigate, useNavigate } from "react-router-dom";
import useUserRoles from "../../hooks/useUserRoles";

const ProtectedRoute = ({ children }) => {
  useNavigate();
  const { userRoles, setUserRoles } = useUserRoles();

  const checkRoles = () => {
    var roles = getRolesForLocation(window.location.pathname);
    return roles.some((x) => userRoles.indexOf(x) >= 0);
  };

  if (UserService.isLoggedIn()) {
    if (userRoles) {
      if (checkRoles()) return children;
      else return <Navigate replace to={"/notAllowed"} />;
    } else return;
  } else {
    return <PublicRoute url={window.location.pathname} />;
  }
};

export default ProtectedRoute;
