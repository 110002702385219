import { useTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/admin-panel/client";
import { style } from "../../../common/select-style/SelectStyle";

const SearchUser = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
  internalUsers,
}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: true,
      label: t("Yes"),
    },
    {
      value: false,
      label: t("No"),
    },
  ];

  var client = new Client();

  const getCompanies = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: {
          companyName: value,
        },
        page: 1,
        pageSize: 2147483647,
      };
      let response = await client.SearchCompanies(data, true);
      return response.results;
    }
  };

  const handleChange = (value) => {
    setFilterData({ ...filterData, companyIdentificators: value });
  };

  return (
    <form>
      <div className="row">
        {internalUsers && (
          <div className="form-group col-md-4">
            <label>{t("UserName")}</label>
            <input
              type="text"
              className="form-control"
              value={filterData.username}
              onChange={(e) => {
                setFilterData({ ...filterData, username: e.target.value });
              }}
            />
          </div>
        )}
        <div className="form-group col-md-4">
          <label>{t("NameAndLastName")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.firstOrLastName}
            onChange={(e) => {
              setFilterData({ ...filterData, firstOrLastName: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.email}
            onChange={(e) => {
              setFilterData({ ...filterData, email: e.target.value });
            }}
          />
        </div>
        {!internalUsers && (
          <>
            <div className="form-group col-md-4">
              <label>{t("Active")}</label>
              <Select
                placeholder={t("SelectFromList")}
                options={options}
                onChange={(e) => {
                  setFilterData({ ...filterData, enabled: e?.value });
                }}
                menuPortalTarget={document.body}
                styles={style}
                value={options.filter((x) => x.value == filterData.enabled)}
                isClearable
              />
            </div>
            <div className="form-group col-md-4">
              <label>{t("CompanyName")}</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                styles={style}
                value={filterData.companyIdentificators}
                getOptionLabel={(e) => e.companyName}
                getOptionValue={(e) => e.companyIdentifier}
                loadOptions={getCompanies}
                onChange={handleChange}
                placeholder="Potrebno je uneti minimum 3 karaktera..."
                isClearable
              />
            </div>
          </>
        )}
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleCallback();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={resetFilters}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchUser;
