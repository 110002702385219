import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "./AlertDialog.style.css";

const AlertDialog = ({
  handleYes,
  handleNo,
  show,
  contentText,
  handleYesText,
  handleNoText,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={show}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleNo();
          } else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "10000" }}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">{t("AreYouSure")}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="alert-dialog-margin">
          <button type="button" className="btn btn-light" onClick={handleNo}>
            {handleNoText ? handleNoText : t("Cancel")}
          </button>
          <button
            type="button"
            className={className + " pull-right"}
            onClick={handleYes}
          >
            {handleYesText}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
