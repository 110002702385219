import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import LicenceStatus from "../../../../common/licence-request-status/LicenceRequestStatus";
import Table from "../../../../common/table/Table.component";
import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  SelectColumnFilter,
} from "../../../../common/table/filterHelpFunction.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DialogModal from "../../../../common/modal/Modal.component";
import api from "../../../../api";
import { env } from "../../../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;

const Licences = ({ data }) => {
  const { t } = useTranslation();
  const [licences, setLicences] = useState([]);
  const filter = {
    filterValue: "equals",
    selectOptions: [
      { value: undefined, label: "Svi" },
      { value: "1", label: "Drugi korak" },
      { value: "2", label: "Treći korak" },
      { value: "3", label: "Odbijen" },
      { value: "4", label: "Odobren" },
      { value: "5", label: "Validacija stranih lica" },
    ],
  };

  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [showDoc, setShowDoc] = useState(false);

  const ShowDocument = () => setShowDoc(true);
  const HideDocument = () => setShowDoc(false);

  const columns = [
    {
      Header: t("RequestNumber"),
      accessor: "ledgerNumber",
      disableFilters: false,
      Cell: ({ row }) => {
        return (
          <a href={"/ExternalExecutorLicences/PreviewLicence/" + row.original.id}>{row.original.ledgerNumber}</a>
        )
      }
    },
    {
      Header: t("ExpertPerson"),
      accessor: "firstAndLastNameOfTheExpertPerson",
      disableFilters: false,
    },
    {
      Header: "Datum početka važnosti dozvole",
      accessor: "licenceValidityDateFrom",
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.licenceValidityDateFrom
              ? format(
                new Date(row.original.licenceValidityDateFrom),
                "dd.MM.yyyy"
              )
              : ""}
          </div>
        );
      },
    },
    {
      Header: "Datum prestanka važnosti dozvole",
      accessor: "licenceValidityDateTo",
      disableFilters: false,
      Filter: DateRangeColumnFilter,
      filter: dateBetweenFilterFn,
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.licenceValidityDateTo
              ? format(
                new Date(row.original.licenceValidityDateTo),
                "dd.MM.yyyy"
              )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      disableFilters: false,
      Filter: SelectColumnFilter,
      filter: filter,
      Cell: ({ row }) => {
        return <LicenceStatus value={row.original.status} />;
      },
    },
    {
      accessor: "download",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          row.original.status != 3 &&
          <button
            type="button"
            className="btn btn-primary table-btn"
            onClick={() => {
              getLicenceDocument(row.original.id, row.original.ledgerNumber);
            }}
          >
            <FontAwesomeIcon icon={solid("download")} />
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    if (data) {
      setLicences(data);
    }
  }, [data]);

  const getLicenceDocument = async (id, name) => {
    let urlName = baseURL + "/LicenceWorkflow/GetBasicLicenceDocument?licenceWorkflowId=" + id;
    let response = await api.get(urlName, {
      responseType: "blob",
    });
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(name);
    ShowDocument();
  };

  const downloadDocument = (url, name) => {
    const link = document.createElement("a");
    link.href = url ? url : doc;
    link.download = name ? name : docName;
    link.click();
  };

  return (
    <>
      <Table
        columns={columns}
        data={licences}
        tableName={"Licences"}
        rememberFilter={false}
      />
      <DialogModal
        show={showDoc}
        onHide={HideDocument}
        title={docName}
        download={true}
        downloadDocument={downloadDocument}
      >
        <div>
          <iframe src={doc} style={{ width: "100%", height: "800px" }} />
        </div>
      </DialogModal>
    </>
  );
};

export default Licences;
