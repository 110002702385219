import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/sap-contract/client";
import { style } from "../../../common/select-style/SelectStyle";

const SearchEquipment = ({
  filterData,
  setFilterData,
  handleCallback,
  resetFilters,
  withContract,
}) => {
  const { t } = useTranslation();
  const [contracts, setContracts] = useState([]);
  const statuses = [
    {
      value: 1,
      label: "Aktivan",
    },
    {
      value: 2,
      label: "Neaktivan",
    },
    {
      value: 4,
      label: "Suspendovan",
    },
  ];

  var client = new Client();

  useEffect(() => {
    if (filterData.sapContract && filterData.sapContract != contracts) {
      setContracts(filterData.sapContract);
    }
  }, [filterData]);

  const handleChange = (value) => {
    setContracts(value);
    setFilterData({
      ...filterData,
      fkSapcontract: value?.id,
      sapContract: value,
    });
  };

  const getContracts = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: { contractNumber: value },
        page: 1,
        pageSize: 2147483647,
        fieldNamesForSort: [
          {
            sortDirection: 0,
            columnName: "ContractNumber"
          }
        ]
      };
      let response = await client.SearchSAPContracts(data, true);
      return response.results;
    }
  };

  return (
    <form>
      <div className="row">
        {withContract && (
          <div className="form-group col-md-4">
            <label>{t("ContractNumber")}</label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              styles={style}
              value={contracts}
              getOptionLabel={(e) => e.contractNumber + " (" + e.contractingParty + ")"}
              getOptionValue={(e) => e.id}
              loadOptions={getContracts}
              onChange={handleChange}
              placeholder="Potrebno je uneti minimum 3 karaktera..."
              isClearable
            />
          </div>
        )}
        <div className="form-group col-md-4">
          <label>{t("EquipmentName")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.name}
            onChange={(e) => {
              setFilterData({ ...filterData, name: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Model")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.model}
            onChange={(e) => {
              setFilterData({ ...filterData, model: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Type")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.type}
            onChange={(e) => {
              setFilterData({ ...filterData, type: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("IdentificationNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.identificationNumber}
            onChange={(e) => {
              setFilterData({
                ...filterData,
                identificationNumber: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Executor")}</label>
          <input
            type="text"
            className="form-control"
            value={filterData.companyName}
            onChange={(e) => {
              setFilterData({ ...filterData, companyName: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Status")}</label>
          <Select
            placeholder={t("SelectFromList")}
            options={statuses}
            onChange={(e) => {
              setFilterData({ ...filterData, status: e?.value });
            }}
            menuPortalTarget={document.body}
            styles={style}
            value={statuses.filter((x) => x.value == filterData.status)}
            isClearable
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleCallback();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setContracts([]);
              resetFilters();
            }}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchEquipment;
