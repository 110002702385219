import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import Client from "../../../api-services/bzr-service/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PersonEquipmentStatus from "../../../common/person-equipment-status/PersonEquipmentStatus";

const SearchPersons = ({
  sapContract,
  edit,
  personsData,
  setPersonData,
  //withContract,
  foreigner,
  licenceRenewal,
  licenceCase,
}) => {
  const { t } = useTranslation();
  const tableName = "BZR_Persons";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    firstName: "",
    lastName: "",
    identityCardNumber: "",
    identityPassportNumber: "",
    //withSapContract: withContract,
    foreigner: foreigner,
    status: 1,
    // fkSapcontract: 0,
  });
  const [bzrPersons, setBzrPersons] = useState([]);
  const [chosenPersons, setChosenPersons] = useState([]);
  const [chosenPersonsIds, setChosenPersonsIds] = useState([]);
  const [show, setShow] = useState(false);

  const columns = [
    {
      disableSortBy: true,
      Header: () => {
        return (
          <input
            type="checkbox"
            value={chosenPersonsIds.length == bzrPersons.dataList?.length}
            checked={chosenPersonsIds.length == bzrPersons.dataList?.length}
            onChange={(e) => selectAllPersons(e.target.checked)}
          />
        );
      },
      accessor: "isChecked",
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            value={chosenPersonsIds.includes(row.original.id)}
            checked={chosenPersonsIds.includes(row.original.id)}
            onChange={(e) => choosePerson(e.target.checked, row.original.id)}
          />
        );
      },
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Status"),
      accessor: "bzrStatus",
      Cell: ({ value }) => {
        return <PersonEquipmentStatus value={value} />;
      },
    },
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("CompanyName"),
      accessor: "companyName",
    },
    {
      Header: t("Foreigner"),
      accessor: "foreigner",
      Cell: ({ row }) => {
        return row.original.foreigner ? t("Yes") : t("No");
      },
    },
    {
      Header: t("IdentityCardNumber"),
      accessor: "identityCardNumber",
    },
    {
      Header: t("IdentityCardPlace"),
      accessor: "identityCardPlace",
    },
    {
      Header: t("PassportNumber"),
      accessor: "identityPassportNumber",
    },
    {
      Header: t("PassportPlace"),
      accessor: "identityPassportPlace",
    },
    {
      accessor: "remove",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeletePerson")}
              onClick={() => {
                removePerson(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("remove")} />
            </button>
          </>
        );
      },
    },
  ];

  var client = new Client();

  useEffect(() => {
    let newFilter = {
      ...filter,
      foreigner: foreigner,
      fkSapcontract: sapContract,
      //withSapContract: withContract,
    };
    if (licenceRenewal) {
      newFilter.isLicenceExtension = true;
      newFilter.caseId = licenceCase;
    }
    setFilter(newFilter);
    resetPersons();
  }, [foreigner, sapContract, licenceCase]);

  useEffect(() => {
    if (personsData) {
      setData(personsData);
    }
  }, [personsData]);

  useEffect(() => {
    reset && setReset(false);
  }, [bzrPersons]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const choosePersons = () => {
    setData(chosenPersons);
    setPersonData(chosenPersons);
    HideDialog();
  };

  const selectAllPersons = (value) => {
    if (value) {
      let persons = bzrPersons.dataList;
      let ids = persons.map((x) => {
        return x.id;
      });
      setChosenPersonsIds(ids);
      setChosenPersons(persons);
    } else {
      setChosenPersonsIds([]);
      setChosenPersons([]);
    }
  };

  const removePerson = (id) => {
    let dataCopy = data.filter((x) => x.id !== id);
    let personsIds = chosenPersonsIds.filter((x) => x !== id);
    setData(dataCopy);
    setPersonData(dataCopy);
    setChosenPersons(dataCopy);
    setChosenPersonsIds(personsIds);
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.SearchPersons({
      filter: filter,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setBzrPersons(result);
  };

  const resetFilter = async () => {
    setReset(true);
    let data = {
      firstName: "",
      lastName: "",
      identityCardNumber: "",
      identityPassportNumber: "",
      foreigner: foreigner,
      status: 1,
      //withSapContract: withContract,
      fkSapcontract: sapContract,
    };
    if (licenceRenewal) {
      data.isLicenceExtension = true;
      data.caseId = licenceCase;
    }
    setFilter(data);
    var response = await client.SearchPersons({
      filter: data,
    });
    setBzrPersons({ dataList: response.results, count: response.totalCount });
  };

  const handleTableRowClick = useCallback((response) => {
    setChosenPersons([...chosenPersons, response.id]);
  });

  const choosePerson = (checked, id) => {
    if (checked) {
      if (!chosenPersonsIds.includes(id)) {
        setChosenPersonsIds([...chosenPersonsIds, id]);
        let chosen = bzrPersons.dataList.filter((x) => x.id == id)[0];
        setChosenPersons([...chosenPersons, chosen]);
      }
    } else {
      let data = chosenPersonsIds.filter((x) => x != id);
      setChosenPersonsIds(data);
      let chosen = chosenPersons.filter((x) => x.id != id);
      setChosenPersons(chosen);
    }
  };

  const resetPersons = () => {
    setData([]);
    setChosenPersons([]);
    setChosenPersonsIds([]);
    if (edit) {
      setPersonData([]);
    }
  };

  return (
    <div className="clearfix">
      {data.length > 0 && (
        <>
          <h5>{t("Persons")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={[
              "id",
              "isChecked",
              edit ? "bzrStatus" : "remove",
              foreigner ? "identityCardNumber" : "identityPassportNumber",
              foreigner ? "identityCardPlace" : "identityPassportPlace"
            ]}
          />
        </>
      )}
      {edit && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
          onClick={ShowDialog}
        >
          {t("AddPersons")}
        </button>
      )}
      <DialogModal show={show} onHide={HideDialog} title={"BZR lica"}>
        <form>
          <div className="row">
            <div className="form-group col-md-6">
              <label>{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.firstName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>{t("LastName")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.lastName}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label>{t("IdentityCardNumber")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.identityCardNumber}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    identityCardNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>{t("PassportNumber")}</label>
              <input
                type="text"
                className="form-control"
                value={filter.identityPassportNumber}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    identityPassportNumber: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-12 text-right">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                searchData();
              }}
            >
              {t("Search")}
            </button>
            <button
              type="button"
              className="btn btn-cancel m-2"
              onClick={resetFilter}
            >
              {t("CancelSearch")}
            </button>
          </div>
          <Table
            columns={columns}
            data={bzrPersons}
            tableName={tableName}
            rememberFilter={rememberFilter}
            fetchFunction={fetchDataForPage}
            serverSide={true}
            setData={setBzrPersons}
            hiddenColumn={[
              "id",
              "remove",
              edit && "bzrStatus",
              foreigner ? "identityCardNumber" : "identityPassportNumber",
              foreigner ? "identityCardPlace" : "identityPassportPlace"
            ]}
            resetFilters={reset}
          />
          <button
            type="button"
            className="btn btn-accept pull-right"
            onClick={choosePersons}
          >
            {t("Choose")}
          </button>
        </form>
      </DialogModal>
    </div>
  );
};

export default SearchPersons;
