import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import UnprocessedInput from "../../../components/bzr-service/unprocessed-input/unprocessedInput.component";
import ProcessedInput from "../../../components/bzr-service/processed-input/processedInput.component";
import { MainContainer } from "../../../common/layout/Layout.style";

const InputReview = () => {
  const [page, setPage] = useState("1");

  useEffect(() => {
    let activePage = sessionStorage.getItem("INPUT_ACTIVE_PAGE");
    if (activePage) {
      setPage(activePage);
    }
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
    sessionStorage.setItem("INPUT_ACTIVE_PAGE", value);
  };

  return (
    <MainContainer>
      <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Čeka na obradu" value="1" className="tab" />
            <Tab label="Obrađeni" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UnprocessedInput />
        </TabPanel>
        <TabPanel value="2">
          <ProcessedInput />
        </TabPanel>
      </TabContext>
    </MainContainer>
  );
};

export default InputReview;
