import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import RenderOnRole from "../../../common/route-and-role/RenderOnRole";
import { env } from "../../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;

const Attachments = ({
  attachments,
  setAttachments,
  attachmentsPreview,
  setAttachmentsPreview,
  canAdd,
  getDocument,
  licenceId
}) => {
  const getAttachment = (id, name) => {
    let url = baseURL + "/LicenceWorkflow/GetDocument?id=" + id;
    getDocument(url, name);
  };

  const getActiveLicenceDocuments = () => {
    let url = baseURL + "/LicenceWorkflow/GetActiveLicenceDocuments?licenceId=" + licenceId;
    getDocument(url, "Dokumentacija");
  }

  return (
    <>
      <h5>
        Prilozi &nbsp;
        {/* {attachmentsPreview.length > 0 && (
          <button type="button" className="btn btn-primary" onClick={getActiveLicenceDocuments}>
            Preuzmi aktuelne verzije dokumenata
          </button>
        )} */}
      </h5>
      {attachmentsPreview.length > 0 ? (
        <div>
          <MultipleFiles
            fileList={attachmentsPreview}
            setFileList={setAttachmentsPreview}
            accept={".pdf"}
            name={"attachmentsPreview"}
            edit={false}
            getDocument={getAttachment}
          />
        </div>
      ) : (
        !canAdd && <span>Nema dodatih priloga.</span>
      )}
      {canAdd && (
        <RenderOnRole roles={["AuthorizedPersonForSecondLevelObjects", "DefenceAndSecurityOfficer"]}>
          <div>
            <MultipleFiles
              fileList={attachments}
              setFileList={setAttachments}
              accept={".pdf"}
              name={"attachments"}
            />
          </div>
        </RenderOnRole>
      )}
    </>
  );
};

export default Attachments;
