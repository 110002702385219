import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputPersonModel } from "../../../models/InputModels";
import Client from "../../../api-services/bzr-service/client";
import CreateInputPerson from "../../../components/bzr-service/create-input-person/createInputPerson.component";
import { MainContainer } from "../../../common/layout/Layout.style";
import { serverValidation } from "../../../common/validation/validation";

const CreatePerson = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, contract } = useParams();

  const [person, setPerson] = useState(new InputPersonModel());
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);

  var client = new Client();

  useEffect(() => {
    if (id) {
      getPerson(id);
    } else {
      setEdit(false);
    }
  }, []);

  const getPerson = async (id) => {
    let data = await client.GetPerson(id);
    setPerson(data);
    setEdit(true);
  };

  const createPerson = async (personData) => {
    if (edit) {
      var response = await client.UpdatePerson(personData);
    } else {
      var response = await client.CreatePerson(personData);
    }
    if (response.code === 2) {
      let result = serverValidation(response.data, t);
      setErrors(result);
    } else if (response && response != "Error") {
      navigate("/BZRService/BZRPersons");
    }
  };

  return (
    <MainContainer>
      <CreateInputPerson
        withContract={contract == "true"}
        addPerson={createPerson}
        personData={person}
        isEdit={edit}
        inputPerson={false}
        errors={errors}
        setErrors={setErrors}
        personIdError={false}
        passwordNumberError={false}
      />
    </MainContainer>
  );
};

export default CreatePerson;
