import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async GetAllExternalUsers() {
    try {
      var response = await api.get(baseURL + "/ExternalUser/GetAll");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchExternalUsers(data) {
    try {
      var response = await api.post(baseURL + "/ExternalUser/Search", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetUser(id) {
    try {
      var response = await api.get(baseURL + "/ExternalUser/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateUser(data) {
    try {
      var response = await api.post(baseURL + "/ExternalUser/Create", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateUser(data) {
    try {
      var response = await api.post(baseURL + "/ExternalUser/Update", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async ResetPassword(data) {
    try {
      var response = await api.post(
        baseURL + "/ExternalUser/ResetPassword",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAllInternalUsers() {
    try {
      var response = await api.get(baseURL + "/InternalUser/GetAll");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetInternalUser(id) {
    try {
      var response = await api.get(baseURL + "/InternalUser/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchInternalUsers(data, isBackground) {
    try {
      var response = await api.post(baseURL + "/InternalUser/Search", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAllRoles() {
    try {
      var response = await api.get(baseURL + "/InternalUser/GetAllRoles");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AssignRoleToInternalUser(data) {
    try {
      var response = await api.post(
        baseURL + "/InternalUser/AssignRoleToInternalUser",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetUserRoles(id, isBackground) {
    try {
      var response = await api.get(
        baseURL + "/InternalUser/GetUserRolesByEmployeeId?employeeId=" + id,
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchObjects(data, isBackground) {
    try {
      var response = await api.post(baseURL + "/ObjectInstance/Search", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchLicenceTypes(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/LicenceType/SearchLiceneType",
        data,
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetObject(id) {
    try {
      var response = await api.get(baseURL + "/ObjectInstance/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateObject(data) {
    try {
      var response = await api.post(baseURL + "/ObjectInstance/Create", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateObject(id, data) {
    try {
      var response = await api.post(
        baseURL + "/ObjectInstance/Update?id=" + id,
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeleteObject(id) {
    try {
      var response = await api.post(
        baseURL + "/ObjectInstance/Delete?id=" + id
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchCompanies(data, isBackground, includeSapContracts = false) {
    try {
      var response = await api.post(
        baseURL + "/Company/Search?includeSapContracts=" + includeSapContracts,
        data,
        {
          isBackground: isBackground,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetCompany(id) {
    try {
      var response = await api.get(
        baseURL + "/Company/GetByCompanyIdentifier?companyIdentifier=" + id
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateCompany(data) {
    try {
      var response = await api.post(baseURL + "/Company/CreateCompany", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateCompany(data) {
    try {
      var response = await api.post(baseURL + "/Company/Update", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeleteCompany(id) {
    try {
      var response = await api.delete(baseURL + "/Company/Delete?id=" + id);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
