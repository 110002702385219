import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { AdditionalLicence } from "../../../models/LicenceModel";
import CustomDatePicker from "../../../common/custom-date-picker/CustomDatePicker.component";
import Client from "../../../api-services/licences/client";
import { style } from "../../../common/select-style/SelectStyle";

const CreateAdditionalLicence = ({
  addLicence,
  data,
  baseLicence,
  d2,
  preview,
  complited,
  objectsError,
  setObjectsError,
  personsError,
  setPersonsError,
}) => {
  const { t } = useTranslation();
  const [licence, setLicence] = useState(new AdditionalLicence());
  const [objects, setObjects] = useState([]);
  const [persons, setPersons] = useState([]);

  var client = new Client();

  useEffect(() => {
    if (data) {
      let objectIds = data.additionalLicenceObjects?.map((x) => {
        let name =
          x.level2ObjectName +
          (x.level3ObjectName ? " -> " + x.level3ObjectName : "");
        return {
          value: x.objectInstanceId,
          label: name,
        };
      });
      let peopleIds = data.additionalLicencePeople?.map((x) => {
        let name =
          "(" +
          (x.identityCardNumber
            ? x.identityCardNumber
            : x.identityPassportNumber) +
          ") " +
          x.firstName +
          " " +
          x.lastName;
        return {
          value: x.id,
          label: name,
        };
      });
      if (data.id == 0 && !preview) {
        data.descriptionOfWorks = baseLicence.purposeOfVisitDescription
          ? baseLicence.purposeOfVisitDescription
          : "";
        data.expertPerson = baseLicence.firstAndLastNameOfTheExpertPerson
          ? baseLicence.firstAndLastNameOfTheExpertPerson
          : "";
      }
      setLicence({
        ...data,
        additionalLicenceObjectIds: objectIds,
        additionalLicencePersonIds: peopleIds,
      });
    }
  }, [data]);

  useEffect(() => {
    if (baseLicence.id) {
      getObjects();
      getPersons();
    }
  }, [baseLicence.id]);

  const getObjects = async () => {
    let response = await client.GetObjectsForD2D3(baseLicence.id);
    let res = response.map((x) => {
      return {
        value: x.id,
        label: x.objectName,
      };
    });
    setObjects(res);
  };

  const getPersons = async () => {
    let data = {
      filter: {
        fkLicenceCase: baseLicence.fkLicenceCase,
      },
    };
    if (complited) {
      data.filter.complited = true;
    }
    let response = await client.SearchLicenceWorkflowPersons(data);
    let res = response.results
      .filter((y) => {
        if (baseLicence.licenceType.documentType != 9) {
          return y.bzrStatus == 1;
        } else {
          return y;
        }
      })
      .map((x) => {
        return {
          value: x.id,
          label:
            "(" +
            (x.identityCardNumber
              ? x.identityCardNumber
              : x.identityPassportNumber) +
            ") " +
            x.firstName +
            " " +
            x.lastName,
        };
      });
    setPersons(res);
  };

  return (
    <>
      <div className="form-group">
        <label>{t("DateFrom")}</label>
        <br />
        <CustomDatePicker
          selected={licence.dateFrom}
          onChange={(date) => setLicence({ ...licence, dateFrom: date })}
          disabled={preview}
        />
      </div>
      <div className="form-group">
        <label>{t("DateTo")}</label>
        <br />
        <CustomDatePicker
          selected={licence.dateTo}
          onChange={(date) => setLicence({ ...licence, dateTo: date })}
          disabled={preview}
        />
      </div>
      <div className="form-group clearfix">
        <label>{t("Objects")}</label>
        <Select
          placeholder={t("SelectFromList")}
          options={objects}
          onChange={(e) => {
            setLicence({ ...licence, additionalLicenceObjectIds: e });
            let err = e.length < 1;
            setObjectsError(err);
          }}
          menuPortalTarget={document.body}
          styles={style}
          value={licence.additionalLicenceObjectIds}
          isMulti
          isDisabled={preview}
        />
        {!preview &&
          licence.additionalLicenceObjectIds?.length != objects.length && (
            <a
              href="#"
              className="pull-right"
              style={{ marginTop: "10px" }}
              onClick={() => {
                setLicence({ ...licence, additionalLicenceObjectIds: objects });
              }}
            >
              {t("ChooseAll")}
            </a>
          )}
        {objectsError && (
          <div className="text-danger">{t("AtLeastOneObjectError")}</div>
        )}
      </div>
      <div className="form-group clearfix">
        <label>{t("Persons")}</label>
        <Select
          placeholder={t("SelectFromList")}
          options={persons}
          onChange={(e) => {
            setLicence({ ...licence, additionalLicencePersonIds: e });
            let err = e.length < 1;
            setPersonsError(err);
          }}
          menuPortalTarget={document.body}
          styles={style}
          value={licence.additionalLicencePersonIds}
          isMulti
          isDisabled={preview}
        />
        {!preview &&
          licence.additionalLicencePersonIds?.length != persons.length && (
            <a
              href="#"
              className="pull-right"
              style={{ marginTop: "10px" }}
              onClick={() => {
                setLicence({ ...licence, additionalLicencePersonIds: persons });
              }}
            >
              {t("ChooseAll")}
            </a>
          )}
        {personsError && (
          <div className="text-danger">{t("AtLeastOnePersonError")}</div>
        )}
      </div>
      <div className="form-group">
        <label>Opis posla</label>
        <textarea
          type="text"
          className="form-control"
          value={licence.descriptionOfWorks}
          onChange={(e) =>
            setLicence({
              ...licence,
              descriptionOfWorks: e.target.value,
            })
          }
          disabled={preview}
        ></textarea>
      </div>
      <div className="form-group">
        <label>{t("firstAndLastNameOfTheExpertPerson")}</label>
        <input
          type="text"
          className="form-control"
          value={licence.expertPerson}
          onChange={(e) =>
            setLicence({
              ...licence,
              expertPerson: e.target.value,
            })
          }
          disabled={preview}
        />
      </div>
      {d2 && (
        <>
          <div className="form-group">
            <label>Odgovorno lice - rukovodilac radova</label>
            <input
              type="text"
              className="form-control"
              value={licence.workManager}
              onChange={(e) =>
                setLicence({
                  ...licence,
                  workManager: e.target.value,
                })
              }
              disabled={preview}
            />
          </div>
          <div className="form-group">
            <label>Odgovorna lica - izvođači radova</label>
            <input
              type="text"
              className="form-control"
              value={licence.responsiblePerson}
              onChange={(e) =>
                setLicence({
                  ...licence,
                  responsiblePerson: e.target.value,
                })
              }
              disabled={preview}
            />
          </div>
          <div className="form-group">
            <label>Izvođači radova</label>
            <input
              type="text"
              className="form-control"
              value={licence.assistentOfResponsiblePerson}
              onChange={(e) =>
                setLicence({
                  ...licence,
                  assistentOfResponsiblePerson: e.target.value,
                })
              }
              disabled={preview}
            />
          </div>
          <div className="form-group">
            <label>Dozvolu izdao</label>
            <input
              type="text"
              className="form-control"
              value={licence.personWhoIssuedTheLicence}
              onChange={(e) =>
                setLicence({
                  ...licence,
                  personWhoIssuedTheLicence: e.target.value,
                })
              }
              disabled={preview}
            />
          </div>
        </>
      )}
      {!preview && (
        <button
          type="button"
          className="btn btn-accept"
          onClick={() => addLicence(licence)}
        >
          {licence.id ? t("Change") : t("Add")}
        </button>
      )}
    </>
  );
};

export default CreateAdditionalLicence;
