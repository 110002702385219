import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LicenceCaseView } from "../../../models/LicenceModel";
import Client from "../../../api-services/licences/client";
import { MainContainer } from "../../../common/layout/Layout.style";
import Licences from "../../../components/external-executor-licences/general-preview/licences/licences.component";
import AdditionalLicences from "../../../components/external-executor-licences/general-preview/additional-licences/additionalLicences.component";
import Persons from "../../../components/external-executor-licences/general-preview/persons/persons.component";
import Equipments from "../../../components/external-executor-licences/general-preview/equipments/equipments.component";
import { SubHeadingLicences } from "./generalPreview.style";

const GeneralPreview = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [licenceCase, setLicenceCase] = useState(new LicenceCaseView());
  const [licences, setLicences] = useState([]);
  const [d2Licences, setD2Licences] = useState([]);
  const [d3Licences, setD3Licences] = useState([]);
  const [persons, setPersons] = useState([]);
  const [equipments, setEquipments] = useState([]);

  var client = new Client();

  useEffect(() => {
    getLicenceCase(id);
  }, []);

  useEffect(() => {
    if (licenceCase) {
      setLicenceCaseView(licenceCase);
    }
  }, [licenceCase]);

  const getLicenceCase = async (id) => {
    let data = await client.GetLicenceCase(id);
    setLicenceCase(data);
  };

  const setLicenceCaseView = (data) => {
    if (data.complitedLicenceWorkflows) {
      setLicences(data.complitedLicenceWorkflows);
    }

    if (data.complitedAdditionalLicences) {
      let d2 = data.complitedAdditionalLicences.filter(
        (item) => item.type === 1
      );
      if (d2.length > 0) {
        d2 = getNames(d2);
      }
      let d3 = data.complitedAdditionalLicences.filter(
        (item) => item.type === 2
      );
      if (d3.length > 0) {
        d3 = getNames(d3);
      }
      setD2Licences(d2);
      setD3Licences(d3);
    }

    if (data.complitedPersons) {
      setPersons(data.complitedPersons);
    }

    if (data.complitedEquipments) {
      setEquipments(data.complitedEquipments);
    }
  };

  const getNames = (data) => {
    data.forEach((y) => {
      var objNames = "";
      var names = "";
      y.additionalLicenceObjects?.forEach((x, index) => {
        objNames +=
          x.level2ObjectName +
          (x.level3ObjectName ? " -> " + x.level3ObjectName : "") +
          (y.additionalLicenceObjects.length != index + 1 ? ", " : "");
      });
      y.objects = objNames;
      y.additionalLicencePeople?.forEach((x, index) => {
        names +=
          x.firstName +
          " " +
          x.lastName +
          (y.additionalLicencePeople.length != index + 1 ? ", " : "");
      });
      y.people = names;
    });
    return data;
  };

  return (
    <>
      <MainContainer>
        <SubHeadingLicences>{t("Licences")}</SubHeadingLicences>
        <Licences data={licences} />
      </MainContainer>
      <MainContainer>
        <SubHeadingLicences>{t("D2Licences")}</SubHeadingLicences>
        <AdditionalLicences data={d2Licences} />
      </MainContainer>
      <MainContainer>
        <SubHeadingLicences>{t("D3Licences")}</SubHeadingLicences>
        <AdditionalLicences data={d3Licences} />
      </MainContainer>
      <MainContainer>
        <SubHeadingLicences>{t("Persons")}</SubHeadingLicences>
        <Persons data={persons} />
      </MainContainer>
      <MainContainer>
        <SubHeadingLicences>{t("Equipments")}</SubHeadingLicences>
        <Equipments data={equipments} />
      </MainContainer>
    </>
  );
};

export default GeneralPreview;
