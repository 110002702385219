import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async GetAllInputs() {
    try {
      var response = await api.get(baseURL + "/InputWorkflow/GetAll");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetInput(id) {
    try {
      var response = await api.get(baseURL + "/InputWorkflow/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchInputs(data) {
    try {
      var response = await api.post(baseURL + "/InputWorkflow/Search", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateInput(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/CreateFirstStepBzr",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async IncompleteSecondStep(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/IncompliteSecondStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AcceptSecondStep(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/AcceptSecondStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async RejectSecondStep(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/RejectSecondStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadFirstAttachment(id) {
    try {
      var response = await api
        .get(
          baseURL + "/InputWorkflow/DownloadAttachmentOneFourthStep?id=" + id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 1.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadSecondAttachment(id) {
    try {
      var response = await api
        .get(
          baseURL + "/InputWorkflow/DownloadAttachmentTwoFourthStep?id=" + id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 2.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async IncompleteFourthStep(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/IncompliteFourthStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AcceptFourthStep(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/AcceptFourthStep",
        data,
        {
          headers: headers,
        }
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async RejectFourthStep(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/RejectFourthStep",
        data
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadFirstAttachmentBzr(id) {
    try {
      var response = await api
        .get(
          baseURL +
            "/InputWorkflow/DownloadAttachmentOneFourthStepBzr?id=" +
            id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 1.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadSecondAttachmentBzr(id) {
    try {
      var response = await api
        .get(
          baseURL +
            "/InputWorkflow/DownloadAttachmentTwoFourthStepBzr?id=" +
            id,
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 2.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetDocument(id, first) {
    try {
      var response = await api
        .get(baseURL + "/InputWorkflow/GetDocument?id=" + id, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = first ? "Prilog 1.pdf" : "Prilog 2.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AcceptFourthStepBzr(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/AcceptFourthStepBzr",
        data,
        {
          headers: headers,
        }
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async RejectFourthStepBzr(data) {
    try {
      var response = await api.post(
        baseURL + "/InputWorkflow/RejectFourthStepBzr",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchPersons(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/SearchPersons",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchEquipments(data, isBackground) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/SearchEquipments",
        data,
        { isBackground: isBackground }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetPerson(id) {
    try {
      var response = await api.get(
        baseURL + "/BzrPersonAndEquipment/GetPerson?id=" + id
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreatePerson(data) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/CreatePerson",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdatePerson(data) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/UpdatePerson",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeletePerson(id) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/DeletePerson?id=" + id
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetEquipment(id) {
    try {
      var response = await api.get(
        baseURL + "/BzrPersonAndEquipment/GetEquipment?id=" + id
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateEquipment(data) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/CreateEquipment",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateEquipment(data) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/UpdateEquipment",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeleteEquipment(id) {
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/DeleteEquipment?id=" + id
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetPersonsFromExcel(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      var response = await api.post(
        baseURL + "/BzrPersonAndEquipment/GetPersonsFromExcel",
        data,
        {
          headers: headers,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DownloadTemplateForPersons() {
    try {
      var response = await api
        .get(baseURL + "/BzrPersonAndEquipment/DownloadTemplateForPersons",
          { responseType: "blob" }
        )
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "bzrPersons.xlsx";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return;
    }
  }
}

export default Client;
