import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async SearchObjects(data) {
    try {
      var response = await api.post(baseURL + "/ObjectInstance/Search", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetObject(id) {
    try {
      var response = await api.get(baseURL + "/ObjectInstance/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateFirstStep(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CreateFirstStep",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateRetroactiveLicence(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CreateRetroactiveLicence",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateLicenceRenewal(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CreateLicenceRenewal",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicenceCase(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetLicenceCase?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async ForwardToTheSecondLevel(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/ForwardToTheSecondLevel",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async ForwardToTheThirdLevel(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/ForwardToTheThirdLevel",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async AcceptCheck(id, isBackground) {
    try {
      var response = await api.post(baseURL + "/LicenceWorkflow/CheckIfTheEmployeeIsAlsoWorkingOnTheThirdStep?linceWorkflowId=" + id,
        { isBackground: isBackground });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async Accept(data) {
    try {
      var response = await api.post(baseURL + "/LicenceWorkflow/Accept", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async Reject(data) {
    try {
      var response = await api.post(baseURL + "/LicenceWorkflow/Reject", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchLicences(data) {
    try {
      var response = await api.post(baseURL + "/LicenceWorkflow/Search", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicence(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicenceWorkflowBase(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetLicenceWorkflowBase?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAdditionalLicences(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetAllAdditionalLicencesByLicenceWorkflow?licenceWorkflowId=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicenceHistory(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetHistoriesByLicenceWorkflow?licenceWorkflowId=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicencePersons(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetPersonsByLicenceWorkflow?licenceWorkflowId=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetLicenceEquipments(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetEquipmentsByLicenceWorkflow?licenceWorkflowId=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetDocument(id) {
    try {
      var response = await api
        .get(baseURL + "/LicenceWorkflow/GetDocument?id=" + id, {
          responseType: "blob",
        })
        .then(({ data }) => {
          const blob = new Blob([data]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = "Prilog 1.pdf";
          link.click();
        });
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async NotifyObject(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/NotifyObjectsAboutLicenceWorkflowOutcome",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAdditionalLicence(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetAdditionalLicence?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateAdditionalLicence(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CreateAdditionalLicence",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateAdditionalLicence(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/UpdateAdditionalLicence",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async RemoveAdditionalLicence(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/RemoveAdditionalLicence",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CancelAdditionalLicence(id) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CancelAdditionalLicence?id=" + id,
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateChangeOfWorkManager(data) {
    try {
      var response = await api.post(
        baseURL + "/LicenceWorkflow/CreateChangeOfWorkManager",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetSubobjects(id) {
    try {
      var response = await api.get(baseURL + "/ObjectInstance/GetObjectsThatHaveSameAuthorizedPersonsByObjectId?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetObjectsForD2D3(id) {
    try {
      var response = await api.get(baseURL + "/LicenceWorkflow/GetObjectsForD2D3?licenceId=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SearchLicenceWorkflowPersons(data) {
    try {
      var response = await api.post(baseURL + "/LicenceWorkflow/SearchLicenceWorkflowPersons", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetAllLicenceTypes() {
    try {
      var response = await api.get(baseURL + "/LicenceType/GetAllLicenceTypes");
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async FilterObjectsForCreateLicence(data) {
    try {
      var response = await api.post(baseURL + "/ObjectInstance/FilterObjectsForCreateLicence", data);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
