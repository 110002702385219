import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogModal from "../../../common/modal/Modal.component";
import { PersonModel } from "../../../models/PersonModel";
import Table from "../../../common/table/Table.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Joi from "joi-browser";
import {
  validateForm,
  handleSave,
} from "../../../common/validation/validation";

const CreatePersons = ({ persons, setPersons, foreigner, licenceRenewalPersons }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState();
  const [person, setPerson] = useState(new PersonModel());
  person.foreigner = foreigner;
  const [companyName, setCompanyName] = useState("");

  const [errors, setErrors] = useState({});
  const [personIdError, setPersonIdError] = useState(false);
  const [passwordNumberError, setPasswordNumberError] = useState(false);
  const [schema, setSchema] = useState({
    companyName: Joi.optional(),
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    identityCardNumber: foreigner ? Joi.optional() : Joi.string().length(9).allow(""),
    identityCardPlace: foreigner ? Joi.optional() : Joi.string().required(),
    identityPassportNumber: foreigner ? Joi.string().required() : Joi.optional(),
    identityPassportPlace: foreigner ? Joi.string().required() : Joi.optional(),
  });

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("CompanyName"),
      accessor: "companyName",
    },
    {
      Header: t("Foreigner"),
      accessor: "foreigner",
      Cell: ({ value }) => {
        return value ? t("Yes") : t("No");
      },
    },
    {
      Header: t("IdentityCardNumber"),
      accessor: "identityCardNumber",
    },
    {
      Header: t("IdentityCardPlace"),
      accessor: "identityCardPlace",
    },
    {
      Header: t("PassportNumber"),
      accessor: "identityPassportNumber",
    },
    {
      Header: t("PassportPlace"),
      accessor: "identityPassportPlace",
    },
    {
      accessor: "edit",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangePerson")}
              onClick={() => {
                setEdit(true);
                setPerson(row.original);
                setIndex(row.index);
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("edit")} />
            </button>
          </>
        );
      },
    },
    {
      accessor: "remove",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeletePerson")}
              onClick={() => {
                removePerson(row.index);
              }}
            >
              <FontAwesomeIcon icon={solid("remove")} />
            </button>
          </>
        );
      },
    },
  ];

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    if (foreigner) {
      schema.identityPassportNumber = Joi.string().required();
      schema.identityPassportPlace = Joi.string().required();
      schema.identityCardNumber = Joi.optional();
      schema.identityCardPlace = Joi.optional();
    } else {
      schema.identityCardNumber = Joi.string().length(9).required();
      schema.identityCardPlace = Joi.string().required();
      schema.identityPassportNumber = Joi.optional();
      schema.identityPassportPlace = Joi.optional();
    }
    setPersons([]);
  }, [foreigner]);

  const addPerson = () => {
    const errorsResult = validateForm(person, schema, t);
    setErrors(errorsResult);

    let personIndex = edit ? index : -1;
    let personIdExist = foreigner
      ? false
      : (
        persons.filter((x, i) => i != personIndex && x.identityCardNumber == person.identityCardNumber && person.identityCardNumber != "")
        .length > 0 || licenceRenewalPersons?.filter(x => x.identityCardNumber == person.identityCardNumber).length > 0
      );
    const passwordNumberExist = !foreigner
      ? false
      : (
        persons.filter((x, i) => i !== personIndex && x.identityPassportNumber === person.identityPassportNumber && person.identityPassportNumber !== "")
        .length > 0 || licenceRenewalPersons?.filter(x => x.identityPassportNumber === person.identityPassportNumber).length > 0
      );

    setPersonIdError(personIdExist);
    setPasswordNumberError(passwordNumberExist);

    if (!errorsResult && !personIdExist && !passwordNumberExist) {
      if (edit) {
        let data = persons;
        data[index] = person;
        setPersons(data);
      } else {
        setPersons([...persons, person]);
      }
      setCompanyName(person.companyName);
      HideDialog();
    }
  };

  const removePerson = (element) => {
    let data = persons.filter((value, index) => index != element);
    setPersons(data);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, person, errors, schema, t);
    setPerson(result.data);
    setErrors(result.errors);
  };

  return (
    <>
      {persons.length > 0 && (
        <Table
          columns={columns}
          data={persons}
          tableName={"Persons"}
          rememberFilter={false}
          hiddenColumn={[
            "id",
            foreigner ? "identityCardNumber" : "identityPassportNumber",
            foreigner ? "identityCardPlace" : "identityPassportPlace"
          ]}
        />
      )}
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
        onClick={() => {
          setEdit(false);
          setPerson({ ...new PersonModel(), companyName: companyName });
          ShowDialog();
        }}
      >
        {t("AddPersons")}
      </button>
      <DialogModal show={show} onHide={HideDialog} title={"Dodavanje lica"}>
        <div className="form-group">
          <label>{t("Name")}</label>
          <input
            type="text"
            className="form-control"
            value={person.firstName}
            onChange={(e) => handlePropertyChange("firstName", e.target.value)}
          />
          {errors?.firstName && (
            <div className="text-danger">{errors.firstName}</div>
          )}
        </div>
        <div className="form-group">
          <label>{t("LastName")}</label>
          <input
            type="text"
            className="form-control"
            value={person.lastName}
            onChange={(e) => handlePropertyChange("lastName", e.target.value)}
          />
          {errors?.lastName && (
            <div className="text-danger">{errors.lastName}</div>
          )}
        </div>
        <div className="form-group">
          <label>{t("CompanyName")}</label>
          <input
            type="text"
            className="form-control"
            value={person.companyName}
            onChange={(e) =>
              handlePropertyChange("companyName", e.target.value)
            }
          />
          {errors?.companyName && (
            <div className="text-danger">{errors.companyName}</div>
          )}
        </div>
        {foreigner ? (
          <>
            <div className="form-group">
              <label>{t("PassportNumber")}</label>
              <input
                type="text"
                className="form-control"
                value={person.identityPassportNumber}
                onChange={(e) => {
                  handlePropertyChange("identityPassportNumber", e.target.value)
                  setPasswordNumberError(false);
                }}
              />
              {(errors?.identityPassportNumber || passwordNumberError) && (
                <div className="text-danger">
                  {passwordNumberError ? t("passwordNumberNotUnique") : errors.identityPassportNumber}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{t("PassportPlace")}</label>
              <input
                type="text"
                className="form-control"
                value={person.identityPassportPlace}
                onChange={(e) =>
                  handlePropertyChange("identityPassportPlace", e.target.value)
                }
              />
              {errors?.identityPassportPlace && (
                <div className="text-danger">{errors.identityPassportPlace}</div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="form-group">
              <label>{t("IdentityCardNumber")}</label>
              <input
                type="text"
                className="form-control"
                value={person.identityCardNumber}
                onChange={(e) => {
                  handlePropertyChange("identityCardNumber", e.target.value);
                  setPersonIdError(false);
                }}
              />
              {(errors?.identityCardNumber || personIdError) && (
                <div className="text-danger">
                  {personIdError ? t("personIdError") : errors.identityCardNumber}
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{t("IdentityCardPlace")}</label>
              <input
                type="text"
                className="form-control"
                value={person.identityCardPlace}
                onChange={(e) =>
                  handlePropertyChange("identityCardPlace", e.target.value)
                }
              />
              {errors?.identityCardPlace && (
                <div className="text-danger">{errors.identityCardPlace}</div>
              )}
            </div>
          </>
        )}
        <button
          type="button"
          className="btn btn-accept pull-right"
          onClick={addPerson}
        >
          {edit ? t("Change") : t("Add")}
        </button>
      </DialogModal>
    </>
  );
};

export default CreatePersons;
