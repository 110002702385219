import React, { useState, useEffect } from "react";
import { NavBarContainer, Logo, LogOutUser } from "./Navigation.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  BZRService,
  Contracts,
  EELicences,
  EmployeeLicences,
  AdminPanel,
} from "./NavigationLinks";
import useAppUrl from "../../hooks/useAppUrl";
import useUserRoles from "../../hooks/useUserRoles";
import { listOfApps } from "../../pages/index/listOfApps";
import { navigateToPage } from "../../common/route-and-role/pathBasedOnRole";
import "./Navigation.style.css";
import UserService from "../../utils/authentification/keycloak";
import RenderOnRole from "../../common/route-and-role/RenderOnRole";

const Navbar = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const { appPath, setAppPath, appName, setAppName } = useAppUrl();
  const { userRoles, setUserRoles } = useUserRoles();
  const [appUrlForRole, setAppUrlForRole] = useState("");

  let path = location.pathname;
  let MenuItems = [];
  let logo = "";

  if (path.includes("BZRService")) {
    MenuItems = BZRService;
  } else if (path.includes("ExternalExecutorLicences")) {
    MenuItems = EELicences;
  } else if (path.includes("EmployeeLicences")) {
    MenuItems = EmployeeLicences;
  } else if (path.includes("Contracts")) {
    MenuItems = Contracts;
  } else if (path.includes("AdminPanel")) {
    MenuItems = AdminPanel;
  } else MenuItems = [];

  if (location.pathname === "/") {
    logo = "";
  } else
    logo = (
      <FontAwesomeIcon className="btn btn-primary" icon={solid("house")} />
    );

  const onClickNavigate = (path, name) => {
    setAppName(name);
    setAppPath(path);
    navigateToPage(path, navigate, userRoles);
  };

  // useEffect(() => {
  //   navigate(appUrlForRole);
  // }, [appUrlForRole]);

  return (
    <>
      <NavBarContainer>
        <Logo>
          <img className="ems-logo" src="/logoems.png" />
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => {
                navigate("/");
                setAppPath("/");
                setAppName("");
              }}
            >
              {logo}
            </button>
            {location.pathname !== "/" && (
              <>
                <div className="dropdown-content app-menu">
                  {listOfApps.map((item, index) => {
                    return (
                      <RenderOnRole key={index} roles={item.roles || []}>
                        <a href="#"
                          onClick={() => {
                            setAppUrlForRole(item.path);
                            onClickNavigate(item.path, item.name);
                          }}>
                          {item.name}
                        </a>
                      </RenderOnRole>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </Logo>
        {MenuItems.map((item, index) => {
          return item.links?.length ? (
            <RenderOnRole key={index} roles={item.role || []}>
              <div key={index} className="dropdown">
                <button className="dropbtn dropbtn-list">
                  {item.buttonName}
                  {item.links?.length > 0 && (
                    <FontAwesomeIcon icon={solid("chevron-down")} />
                  )}
                </button>
                <div className="dropdown-content">
                  {item.links?.map((links, index) => {
                    return (
                      <Link key={index} to={links.path}>
                        {links.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </RenderOnRole>
          ) : (
            <RenderOnRole key={index} roles={item.role || []}>
              <Link
                className={item.path == window.location.pathname ? "active" : ""}
                key={index}
                to={item.path}
              >
                {item.buttonName}
              </Link>
            </RenderOnRole>
          );
        })}
        {UserService.isLoggedIn() && (
          <LogOutUser>
            <div className="dropdown">
              <button className="dropbtn">
                {UserService.getFullName()}
                <FontAwesomeIcon icon={solid("user")} />
              </button>
              <div className="dropdown-content">
                <Link
                  to="/MyProfile"
                  onClick={() => {
                    setAppUrlForRole("/");
                    setAppPath("/");
                    setAppName("");
                  }}
                >
                  Moj profil
                </Link>
                <button
                  className="logout-btn"
                  onClick={() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    UserService.doLogout({
                      redirectUri: window.location.origin + "/",
                    });
                  }}
                >
                  {t("LogOut")}
                  <FontAwesomeIcon icon={solid("sign-out")} />
                </button>
              </div>
            </div>
          </LogOutUser>
        )}
      </NavBarContainer>
    </>
  );
};

export default Navbar;
