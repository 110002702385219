import styled from "styled-components";

export const NavBarContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 5px 9px -8px rgb(0 0 0 / 22%);
  min-height: 53px;

  svg {
    margin-left: 7px;
  }

  a {
    float: left;
    font-size: 16px;
    color: #333;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-bottom: 2px solid #fff;

    &:hover {
      border-bottom: 2px solid #ddd;
    }
    &.active {
      border-bottom: 2px solid #e81f27;
    }
  }
  .dropdown {
    float: left;

    .dropbtn {
      border: none;
      outline: none;
      color: #333;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
    }

    .dropbtn-list {
      font-size: 16px;
      padding: 14px 16px;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 120px;
    width: fit-content;
    box-shadow: 0px 5px 21px -2px rgb(0 0 0 / 22%);
    z-index: 1;
    border-radius: 7px;
    border: 1px solid #ddd;
    z-index: 9999;

    a {
      float: none;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;

      &:hover {
        background-color: #e81f27;
        color: #fff;
      }
    }
  }

  .dropdown-content.app-menu {
    width: 500px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .logo-span {
    cursor: default;
    font-size: 14px;
  }

  .ems-logo {
    height: 41px;
    margin-top: 6px;
    float: left;
    margin-right: 10px;
  }
`;

export const Logo = styled.div`
  float: left;
  position: absolute;
  left: 19px;
  top: 0px;
  font-size: 32px !important;
  font-weight: lighter;
`;

export const LogOutUser = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 13px 0px;

  svg {
    margin-right: 5px;
  }

  span {
    margin-right: 15px;
  }
`;
