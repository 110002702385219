import UserService from "../../utils/authentification/keycloak";
import useUserRoles from "../../hooks/useUserRoles";

const RenderOnRole = ({ roles, children }) => {
  const { userRoles, setUserRoles } = useUserRoles();
  const listOfRoles = [...roles, "Superadministrator"];

  const checkRoles = () => {
    return listOfRoles.some((x) => userRoles?.indexOf(x) >= 0);
  };

  if (checkRoles()) return children;
  else return null;
};

export default RenderOnRole;
