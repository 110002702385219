import React from 'react';

const StatusEnum = {
  1: "Drugi korak", // SecondLevel
  2: "Treći korak", // ThirdLevel
  3: "Odbijen", // Reject 
  4: "Odobren", // Accept 
  5: "Validacija stranih lica", // ValidationForForeigners
};

export const StatusForValidation = [5];
export const StatusForThirdStep = [1];
export const StatusForAcception = [1, 2];
export const StatusForRejection = [1, 2, 5];
export const StatusAccepted = [4];
export const StatusForAdditionalLicences = [1, 4];

const LicenceStatus = ({ value }) => {
  return <span title={StatusEnum[value]}>{StatusEnum[value]}</span>;
};

export default LicenceStatus;