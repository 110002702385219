import { dateWithoutTimezone } from "../common/custom-date-picker/CustomDatePicker.component";

class ExternalUserModel {
  constructor(
    id = "",
    companyName = "",
    companyIdentifier = "",
    userName = "",
    firstName = "",
    lastName = "",
    email = "",
    password = "",
    temporaryPassword = false,
    verifyEmail = true,
    enabled = true
  ) {
    this.id = id;
    this.companyName = companyName;
    this.companyIdentifier = companyIdentifier;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.password = password;
    this.temporaryPassword = temporaryPassword;
    this.verifyEmail = verifyEmail;
    this.enabled = enabled;
  }
}

class InternalUserModel {
  constructor(
    id = "",
    userName = "",
    firstName = "",
    lastName = "",
    email = "",
    enabled = true,
    roles = []
  ) {
    this.id = id;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.enabled = enabled;
    this.roles = roles;
  }
}

class SearchUserModel {
  constructor(
    firstOrLastName = "",
    username = "",
    email = "",
    companyIdentificators = null
  ) {
    this.firstOrLastName = firstOrLastName;
    this.username = username;
    this.email = email;
    this.companyIdentificators = companyIdentificators;
  }
}

class UserReplacementModel {
  constructor(
    replacementEmployeeId = "",
    dateFrom = dateWithoutTimezone(new Date()),
    dateTo = dateWithoutTimezone(new Date()),
    permanent = false
  ) {
    this.replacementEmployeeId = replacementEmployeeId;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.permanent = permanent;
  }
}

export {
  ExternalUserModel,
  InternalUserModel,
  SearchUserModel,
  UserReplacementModel,
};
